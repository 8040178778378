/* ====================== 07/06/24
 */

.indi-meet {
  max-width: 70%;
  text-align: center;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 46px;
  text-transform: capitalize;
  background: linear-gradient(90deg, #af2245, #882767, #602d8a);
  color: var(--gradient, transparent);
  background-clip: text;
  -webkit-background-clip: text;
}
.indi-intigrated {
  max-width: 56%;
  margin-bottom: 130px;
  margin-top: 27px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #111535;
}

.individual-page {
  background-image: linear-gradient(to right, #d8ebe3, #e0c1da);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 35px 65px 35px;
  box-sizing: border-box;
}

.z-services-list li img {
  height: 100px;
  margin: 0 15px 0 0;
}

.iv-drip-1 {
  width: 160px;
  height: 160px;
}
.iv-drip-2 {
  width: 35px;
  /* height: 30px; */
}
.ind-card {
  margin: -130px 0px 0px 0px;
}
.indi-connect {
  background-color: #f7e9ec;
  border-radius: 12px;
  /* padding: 23px 10px 23px 10px; */
}
.link-border {
  /* text-decoration: underline; */
  border-bottom: 1px solid #af2245d0;
}
.insurance-link {
  text-align: right;
}

.get-size {
  height: 260px !important;
  width: 238px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: none !important;
}
.get-size-2 {
  min-height: 360px;
  /* width: 270px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: none !important;
  border-radius: 3% !important;
}

.to-link {
  text-decoration: underline;
}
.indi-order-page {
  margin: 0 0 20px 0px;
  display: flex;
  align-items: center;
}
.indi-how-order {
  text-align: center;
}
.undraw-approve-qwp-71 {
  margin: 0 0.9px 20px 0;
}
.how-to-order {
  background: linear-gradient(90deg, #af2245, #882767, #602d8a);
  color: var(--gradient, transparent);
  -webkit-background-clip: text;
  margin-bottom: 20px !important;
}
.img-sub-title {
  background: linear-gradient(90deg, #af2245, #882767, #602d8a);
  color: var(--gradient, transparent);
  -webkit-background-clip: text;
  margin: 0 0.9px 30px 0;
}
.img-dr-title {
  background: linear-gradient(90deg, #af2245, #882767, #602d8a);
  color: var(--gradient, transparent);
  -webkit-background-clip: text;
}
.vector-1 {
  position: relative;
  top: -100px;
}

.vector-1-width {
  width: 15rem;
}

.des-width {
  width: 170px;
}

.indi-licens {
  /* display: flex; */
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-start;
  box-sizing: border-box;
}

.indi-licensed-doctors {
  margin: 13px 5px 0 0;
  /* width: 859.4px; */
  font-family: "Poppins";
  font-weight: 600;
  font-size: 32px;
  text-transform: capitalize;
  background: linear-gradient(90deg, #af2245, #882767, #602d8a);
  color: var(--gradient, transparent);
  background-clip: text;
  -webkit-background-clip: text;
}

.indi-licens-ellipse {
  border-radius: 10px;
  background: rgba(175, 34, 69, 0.1);
  margin-bottom: 14px;
  display: flex;
  flex-direction: row;
  padding: 5px 21.9px 4px 12px;
  box-sizing: border-box;
  align-items: center;
  /* max-width: 30%; */
}

.indi-licens-ellipse-sub {
  border-radius: 12px;
  background: rgba(32, 210, 103, 0.2);
  margin: 15px 19.9px 15px 0;
  display: flex;
  padding: 3px;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
}

.indi-licens-ellipse-circle {
  border-radius: 9px;
  background: #20d267;
  width: 18px;
  height: 18px;
}

.indi-doctor896 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: var(--text-color-1, #0c273c);
}

.check-uncheck {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.group-1261155840 {
  margin: 0 101px 34px 101px;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  column-gap: 20px;
  width: 1870px;
  box-sizing: border-box;
}

.get-size-3 {
  height: 390px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: none !important;
  border-radius: 3% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.get-ourdoc {
  display: flex;
}
.iv-drip-3 {
  width: 130px;
  height: 130px;
}

.text-color {
  color: #0c273c;
}

.book-appoi {
  /* width: 47%; */
  height: 320px;
  background-image: linear-gradient(to right, #d8ebe3, #e0c1da);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* --------------- */
.card-slider {
  position: relative;
  /* width: 28%; */
  /* overflow: hidden; */
}

.slider-wrapper {
  display: flex;
  transition: transform 0.5s ease;
}

.indi-slide-card {
  flex: 0 0 100%; /* Ensure each card takes full width of the slider */
  padding: 20px;
  box-sizing: border-box;
  background-color: #f0f0f0;
  margin-right: 10px; /* Adjust as needed */
}

.prev-btn,
.next-btn {
  position: relative;
  /* top: -16rem; */
  bottom: -20px;
  transform: translateY(-50%);
  /* background-color: rgba(0, 0, 0, 0.5); */
  color: white;
  border: none;
  /* padding: 30px; */
  cursor: pointer;
  z-index: 1;
}
.next-btn {
  margin-left: 20px;
}

@media (max-width: 1024px) {
  .indi-order-page .vector-1 {
    display: none;
  }

  .individual-page {
    padding: 0px 25px 65px 25px;
  }

  .indi-order-page {
    width: 100%;
    justify-content: space-between;
  }
  /* .ind-card {
    margin: -130px 0px 0px 50px;
} */
  /* .indicard2{
  margin-left: 43px;
} */
}

@media (max-width: 820px) {
  /* .ind-card {
    margin: -130px 0px 0px 68px;
} */
  /* .indicard2{
  margin-left: 67px;
} */
  .indi-meet {
    max-width: 100%;
  }

  .indi-intigrated {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .get-size-2 {
    min-height: auto;
  }
}

@media (max-width: 600px) {
  /* .ind-card {
    margin: -130px 0px 0px 27px;
} */
  /* .indicard2{
  margin-left: 67px;
}  */

  .indi-meet {
    max-width: 100%;
  }

  .indi-intigrated {
    max-width: 100%;
  }
  .get-size {
    width: 100%;
  }
  .insurance-link {
    text-align: left;
  }
  .indi-order-page {
    flex-direction: row;
    align-items: center;
  }

  .vector-1 {
    width: 80px;
  }
  .how-to-join {
    padding: 20px 0;
  }

  .indi-order-page {
    flex-wrap: wrap;
    gap: 9px;
    justify-content: center;
  }

  .indi-how-order img {
    max-width: 100%;
    height: auto;
  }

  .vector-1 {
    width: 50px;
  }
  .indi-licens {
    flex-wrap: wrap;
    padding: 1.2rem;
  }
  .indi-licens-ellipse {
    max-width: 100%;
  }
  .indi-points {
    padding: 1.3rem;
  }
  .card-slider,
  .slider-btn {
    padding: 1.4rem;
  }
  .book-an-appoi {
    margin: 1.3rem;
  }
  .accept-bupa {
    margin: 1.3rem;
  }
  .book-appoi {
    padding: 8px;
  }
  .z-services-list li img {
    height: 70px;
  }
  .zw_24_sm {
    font-size: 16px;
  }
  .get-ourdoc {
    justify-content: center;
  }
}
/* @media (width: 540px) {
  .ind-card {
    margin: -130px 0px 0px 100px;
}
.indicard2{
  margin-left: 67px;
} 

.indi-meet {
  max-width: 100%;
}

.indi-intigrated {
  max-width: 100%;
}
} */
