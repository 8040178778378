html {
  font-size: 62.5%;
}
* {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
}
input {
  border: none;
}
/* body {
  width: 144rem;
} */
.full-body-Zqe {
  width: 100%;
  height: 120.8rem;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
}
.full-body-Zqe .group-182-srL {
  width: 124rem;
  height: 6.5rem;
  position: absolute;
  left: 10rem;
  top: 2.4rem;
  display: flex;
  align-items: center;
}
.full-body-Zqe .group-182-srL .logo-PZn {
  width: 6.7997rem;
  height: 6.5001rem;
  position: relative;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.full-body-Zqe .group-182-srL .auto-group-ziwe-8nG {
  box-sizing: border-box;
  padding: 1.2rem 0rem 0.4rem 31.7rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.full-body-Zqe .group-182-srL .auto-group-ziwe-8nG .group-10-riG {
  margin: 1.2rem 12.2rem 1.1rem 0rem;
  height: calc(100% - 2.3rem);
  display: flex;
  column-gap: 4.6rem;
  align-items: center;
  flex-shrink: 0;
}
.full-body-Zqe .group-182-srL .auto-group-ziwe-8nG .group-10-riG .home-mqE {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.full-body-Zqe .group-182-srL .auto-group-ziwe-8nG .group-10-riG .about-W28 {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-182-srL
  .auto-group-ziwe-8nG
  .group-10-riG
  .join-as-provider-q4Q {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.full-body-Zqe .group-182-srL .auto-group-ziwe-8nG .group-10-riG .get-care-N4L {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.full-body-Zqe .group-182-srL .auto-group-ziwe-8nG .group-179-71v {
  margin: 0.1rem 5.7rem 0rem 0rem;
  width: 5.6rem;
  height: 2.6rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.full-body-Zqe .group-182-srL .auto-group-ziwe-8nG .auto-group-tnyc-pwv {
  width: 14.9rem;
  height: 100%;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 0.8888888889;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  background-color: #af2245;
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.full-body-Zqe .rectangle-39544-hkp {
  width: 144rem;
  height: 19.2rem;
  position: absolute;
  left: 0;
  top: 12.2rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
}
.full-body-Zqe .group-1261154093-paY {
  width: 85.3rem;
  height: 2.7rem;
  position: absolute;
  left: 10rem;
  top: 14.6rem;
  display: flex;
  align-items: center;
}
.full-body-Zqe .group-1261154093-paY .group-1261154076-8r8 {
  margin-right: 10.3rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154093-paY
  .group-1261154076-8r8
  .group-1261154072-HU8 {
  margin: 0rem 1.64rem 0.1rem 0rem;
  width: 2.7599rem;
  height: 2.4rem;
  position: relative;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.full-body-Zqe .group-1261154093-paY .group-1261154076-8r8 .back-QYk {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.full-body-Zqe .group-1261154093-paY .group-1261154073-Yex {
  margin: 1.21rem 0rem 1.292rem 0rem;
  height: calc(100% - 2.502rem);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154093-paY
  .group-1261154073-Yex
  .rectangle-39545-sx8 {
  margin-right: 1.0913rem;
  width: 15.7066rem;
  height: 0.198rem;
  background-color: #af2245;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154093-paY
  .group-1261154073-Yex
  .rectangle-39546-DWC {
  margin-right: 1.0913rem;
  width: 15.7066rem;
  height: 0.198rem;
  background-color: #ffffff;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154093-paY
  .group-1261154073-Yex
  .rectangle-39547-MMW {
  margin-right: 1.0912rem;
  width: 15.7066rem;
  height: 0.198rem;
  background-color: #ffffff;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154093-paY
  .group-1261154073-Yex
  .rectangle-39548-hRN {
  width: 15.7066rem;
  height: 0.198rem;
  background-color: #ffffff;
  flex-shrink: 0;
}
.full-body-Zqe .frame-37120-Ew6 {
  box-sizing: border-box;
  padding: 0.8rem 2.4rem;
  width: 45.8rem;
  height: 5.3rem;
  position: absolute;
  left: 10rem;
  top: 21.4rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 1.2rem;
}
.full-body-Zqe .frame-37120-Ew6 .search-your-lab-tests-packages-LDS {
  margin-right: 12.7rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  color: #999999;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.full-body-Zqe .frame-37120-Ew6 .line-1-rha {
  margin-right: 2.2rem;
  width: 0rem;
  height: 3.7rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.full-body-Zqe .frame-37120-Ew6 .vector-PhW {
  margin-top: 0.1rem;
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.full-body-Zqe .group-1261154677-uvk {
  box-sizing: border-box;
  padding: 2rem 3.2rem;
  width: 51.7rem;
  height: 19.2rem;
  position: absolute;
  left: 10rem;
  top: 33.8rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
}
.full-body-Zqe .group-1261154677-uvk .group-1261154676-RPJ {
  width: 36.5rem;
  height: 100%;
  display: flex;
  align-items: flex-start;
}
.full-body-Zqe
  .group-1261154677-uvk
  .group-1261154676-RPJ
  .group-1261154660-Arg {
  margin-right: 2.4rem;
  width: 16.7rem;
  height: 15.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154677-uvk
  .group-1261154676-RPJ
  .group-1261154672-JT6 {
  row-gap: 1.6rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154677-uvk
  .group-1261154676-RPJ
  .group-1261154672-JT6
  .sanar-care-T56 {
  font-size: 1.605rem;
  font-weight: 600;
  line-height: 1.0177711424;
  text-transform: capitalize;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154677-uvk
  .group-1261154676-RPJ
  .group-1261154672-JT6
  .laboratory-home-visit-nNG {
  font-size: 1.0052rem;
  font-weight: 700;
  line-height: 1.624999917;
  text-transform: capitalize;
  color: #86909d;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154677-uvk
  .group-1261154676-RPJ
  .group-1261154672-JT6
  .view-selected-details-vDa {
  font-size: 1.605rem;
  font-weight: 600;
  line-height: 1.0177711424;
  text-transform: capitalize;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.full-body-Zqe .add-details-qbS {
  width: 18.9rem;
  height: 1.1rem;
  position: absolute;
  left: 66.1rem;
  top: 33.8rem;
  font-size: 3rem;
  font-weight: 600;
  line-height: 0.3350815773;
  text-transform: uppercase;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.full-body-Zqe .select-patient-YVr {
  width: 11.6rem;
  height: 1.7rem;
  position: absolute;
  left: 66.1rem;
  top: 38.6rem;
  font-size: 1.605rem;
  font-weight: 600;
  line-height: 1.0177711424;
  text-transform: capitalize;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.full-body-Zqe .rectangle-39553-F9N {
  width: 67.9rem;
  height: 22.1rem;
  position: absolute;
  left: 66.1rem;
  top: 42.7rem;
  border: solid 0.1rem #111535;
  box-sizing: border-box;
  border-radius: 0.7rem;
}
.full-body-Zqe .rectangle-39611-AGL {
  width: 144rem;
  height: 120.8rem;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(166, 166, 166, 0.6000000238);
}
.full-body-Zqe .group-1261154722-hXA {
  width: 79.4rem;
  height: 91.4rem;
  position: absolute;
}
.full-body-Zqe .group-1261154722-hXA .group-1261154609-qNU {
  width: 79.4rem;
  height: 91.4rem;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: contain;
  vertical-align: top;
}
.full-body-Zqe .group-1261154722-hXA .add-patient-yzU {
  width: 18.8rem;
  height: 2.6rem;
  position: absolute;
  left: 6.2rem;
  top: 5.3rem;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 0.8125;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.full-body-Zqe .group-1261154722-hXA .group-1261154720-Vhv {
  width: 67.1rem;
  height: 76rem;
  position: absolute;
  left: 6.2rem;
  top: 11.1rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ {
  box-sizing: border-box;
  padding-bottom: 3.2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-vu2u-N12 {
  margin: 0rem 0.3rem 3.2rem 0rem;
  width: calc(100% - 0.3rem);
  height: 8.6rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-vu2u-N12
  .group-1261154715-Upk {
  margin-right: 3.6rem;
  width: 31.6rem;
  height: 100%;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-vu2u-N12
  .group-1261154715-Upk
  .group-1261154698-DXS {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.6rem;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-vu2u-N12
  .group-1261154715-Upk
  .group-1261154698-DXS
  .first-name-AxU {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-vu2u-N12
  .group-1261154715-Upk
  .group-1261154698-DXS
  .auto-group-vbfz-uv4 {
  box-sizing: border-box;
  padding: 1.2rem 3.2rem;
  width: 100%;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #717171;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  border: solid 0.2rem #d2d2d2;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-vu2u-N12
  .group-1261154716-BMn {
  width: 31.6rem;
  height: 100%;
  position: relative;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-vu2u-N12
  .group-1261154716-BMn
  .last-name-8np {
  width: 9.6rem;
  height: 2.6rem;
  position: absolute;
  left: 3.2rem;
  top: 4.8rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #717171;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-vu2u-N12
  .group-1261154716-BMn
  .group-1261154699-qhE {
  width: 31.6rem;
  height: 8.6rem;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0.6rem;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-vu2u-N12
  .group-1261154716-BMn
  .group-1261154699-qhE
  .last-name-yYY {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-vu2u-N12
  .group-1261154716-BMn
  .group-1261154699-qhE
  .rectangle-39578-im2 {
  width: 100%;
  height: 5rem;
  border: solid 0.2rem #d2d2d2;
  box-sizing: border-box;
  border-radius: 0.6rem;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-zghs-RvL {
  margin-right: 0.3rem;
  width: calc(100% - 0.3rem);
  height: 8.6rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-zghs-RvL
  .group-1261154700-MJC {
  margin-right: 3.6rem;
  width: 31.6rem;
  height: 100%;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-zghs-RvL
  .group-1261154700-MJC
  .nationality-hN4 {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-zghs-RvL
  .group-1261154700-MJC
  .auto-group-vwdn-ovt {
  box-sizing: border-box;
  padding: 1.2rem 3.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  border: solid 0.2rem #d2d2d2;
  border-radius: 0.6rem;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-zghs-RvL
  .group-1261154700-MJC
  .auto-group-vwdn-ovt
  .selected-nationality-69J {
  margin-right: 5.1rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #717171;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-zghs-RvL
  .group-1261154700-MJC
  .auto-group-vwdn-ovt
  .vector-LJY {
  width: 2.1rem;
  height: 1rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-zghs-RvL
  .group-1261154713-2x4 {
  width: 31.6rem;
  height: 100%;
  position: relative;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-zghs-RvL
  .group-1261154713-2x4
  .passport-id-number-P1v {
  width: 18.1rem;
  height: 2.6rem;
  position: absolute;
  left: 3.2rem;
  top: 4.7rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #717171;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-zghs-RvL
  .group-1261154713-2x4
  .group-1261154701-gmi {
  width: 31.6rem;
  height: 8.6rem;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0.6rem;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-zghs-RvL
  .group-1261154713-2x4
  .group-1261154701-gmi
  .passport-id-number-EYL {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-ygza-pVJ
  .auto-group-zghs-RvL
  .group-1261154713-2x4
  .group-1261154701-gmi
  .rectangle-39580-n44 {
  width: 100%;
  height: 5rem;
  border: solid 0.2rem #d2d2d2;
  box-sizing: border-box;
  border-radius: 0.6rem;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-etlp-VUG {
  margin-bottom: 3.2rem;
  width: 100%;
  height: 8.6rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-etlp-VUG
  .group-1261154702-2yz {
  margin-right: 3.9rem;
  width: 31.6rem;
  height: 100%;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-etlp-VUG
  .group-1261154702-2yz
  .gender-Bbz {
  margin-bottom: 1rem;
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 1.2380952381;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-etlp-VUG
  .group-1261154702-2yz
  .auto-group-b9de-vZa {
  box-sizing: border-box;
  padding: 2rem 3.2rem 2rem 26.3rem;
  width: 100%;
  border: solid 0.2rem #d2d2d2;
  border-radius: 0.6rem;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-etlp-VUG
  .group-1261154702-2yz
  .auto-group-b9de-vZa
  .vector-ry2 {
  width: 2.1rem;
  height: 1rem;
  object-fit: contain;
  vertical-align: top;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-etlp-VUG
  .group-1261154703-z3e {
  width: 31.6rem;
  height: 100%;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-etlp-VUG
  .group-1261154703-z3e
  .dob-8vY {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-etlp-VUG
  .group-1261154703-z3e
  .rectangle-39579-UUc {
  width: 100%;
  height: 5rem;
  border: solid 0.2rem #d2d2d2;
  box-sizing: border-box;
  border-radius: 0.6rem;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .group-1261154704-1De {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .group-1261154704-1De
  .blood-group-Zkx {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .group-1261154704-1De
  .auto-group-z1de-hsA {
  box-sizing: border-box;
  padding: 2rem 3.2rem 2rem 61.8rem;
  width: 100%;
  border: solid 0.2rem #d2d2d2;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .group-1261154704-1De
  .auto-group-z1de-hsA
  .vector-qyN {
  width: 2.1rem;
  height: 1rem;
  object-fit: contain;
  vertical-align: top;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa {
  box-sizing: border-box;
  padding-top: 3.2rem;
  width: 100%;
  row-gap: 3.2rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .group-1261154945-6PW {
  width: 67rem;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .group-1261154945-6PW
  .mobile-number-optional-FGQ {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .group-1261154945-6PW
  .group-1261154944-nXE {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .group-1261154945-6PW
  .group-1261154944-nXE
  .group-1261154710-LYk {
  margin-right: 3.3rem;
  box-sizing: border-box;
  padding: 0.6rem 1.8rem 0.6rem 0.7rem;
  height: 100%;
  display: flex;
  align-items: center;
  border: solid 0.2rem #d2d2d2;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .group-1261154945-6PW
  .group-1261154944-nXE
  .group-1261154710-LYk
  .rectangle-39628-3i4 {
  margin-right: 1.1rem;
  width: 5.2rem;
  height: 3.8rem;
  object-fit: cover;
  vertical-align: top;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .group-1261154945-6PW
  .group-1261154944-nXE
  .group-1261154710-LYk
  .item-966-NVS {
  margin-right: 10.5rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #221f1f;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .group-1261154945-6PW
  .group-1261154944-nXE
  .group-1261154710-LYk
  .vector-6gL {
  width: 2.1rem;
  height: 1rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .group-1261154945-6PW
  .group-1261154944-nXE
  .group-1261154711-DW4 {
  box-sizing: border-box;
  padding: 1.2rem 2.1rem;
  width: 37.6rem;
  height: 100%;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #717171;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  border: solid 0.2rem #d2d2d2;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .group-1261154709-tMJ {
  width: 66.8rem;
  height: 8.6rem;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .group-1261154709-tMJ
  .group-1261154706-3EC {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.6rem;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .group-1261154709-tMJ
  .group-1261154706-3EC
  .relationship-zv8 {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .group-1261154709-tMJ
  .group-1261154706-3EC
  .auto-group-ij8u-8mS {
  box-sizing: border-box;
  padding: 1.2rem 2.9rem 1.2rem 3.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  border: solid 0.2rem #d2d2d2;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .group-1261154709-tMJ
  .group-1261154706-3EC
  .auto-group-ij8u-8mS
  .relationship-rxL {
  margin-right: 47.5rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #717171;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .group-1261154709-tMJ
  .group-1261154706-3EC
  .auto-group-ij8u-8mS
  .vector-PxG {
  margin-top: 0rem;
  width: 2.1rem;
  height: 1rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v {
  max-width: 64.4rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #000000;
  font-family: Poppins, "Source Sans Pro";
  flex-shrink: 0;
}
/* .full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v
  .by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v-sub-0 {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #000000;
  font-family: Poppins, "Source Sans Pro";
}
 
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v
  .by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v-sub-2 {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #000000;
  font-family: Poppins, "Source Sans Pro";
}
 
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v
  .by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v-sub-4 {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #000000;
  font-family: Poppins, "Source Sans Pro";
} */
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v
  .by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v-sub-5 {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  text-decoration-line: underline;
  color: #602d8a;
  font-family: Poppins, "Source Sans Pro";
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v
  .by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v-sub-6 {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #000000;
  font-family: Poppins, "Source Sans Pro";
}
.full-body-Zqe
  .group-1261154722-hXA
  .group-1261154720-Vhv
  .auto-group-819n-ZPa
  .by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v
  .by-clicking-add-patient-you-agree-to-our-terms-condition-and-privacy-policy-u9v-sub-7 {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  text-decoration-line: underline;
  color: #602d8a;
  font-family: Poppins, "Source Sans Pro";
}

.react-datepicker-wrapper {
  width: 100%;
}
