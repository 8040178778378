html {
  font-size: 62.5%;
}

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

input {
  border: none;
}

.my-wallet-TQp {
  margin-top: 9.5rem;
}

.my-wallet-TQp .auto-group-sjb2-96c {
  margin-bottom: 3.6rem;
  display: flex;
  padding-left: 11rem;
  align-items: center;
  height: 29.5rem;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
}

.my-zwaara-section {
  width: 95rem;
}

.my-wallet-TQp .auto-group-sjb2-96c .frame-1261154258-Ueg {
  padding: 1.2rem 1.796rem 0.759rem 1.6rem;
  width: 29rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
}

.my-wallet-TQp
  .auto-group-sjb2-96c
  .frame-1261154258-Ueg
  .frame-1261154257-mdn {
  display: flex;
  align-items: center;
}

.my-wallet-TQp
  .auto-group-sjb2-96c
  .frame-1261154258-Ueg
  .frame-1261154257-mdn
  .group-1261154933-XN4 {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.my-wallet-TQp
  .auto-group-sjb2-96c
  .frame-1261154258-Ueg
  .frame-1261154257-mdn
  .group-1261154933-XN4
  .vector-3bJ {
  margin-bottom: 0.75rem;
  width: 2.2667rem;
}

.text-black-filter {
  filter: brightness(0) saturate(100%);
}

/* .my-wallet-TQp .auto-group-sjb2-96c .frame-1261154258-Ueg .frame-1261154257-mdn .group-1261154933-XN4 .appointments-mnC {
  font-size: 1.1333rem;
  font-weight: 500;
  color: #000000;
  font-family: Poppins, "Source Sans Pro";
} */

.my-wallet-TQp
  .auto-group-sjb2-96c
  .frame-1261154258-Ueg
  .frame-1261154257-mdn
  .line-4-JGL {
  margin: 0 1.5rem;
  width: 1.8px;
  height: 4rem;
}

.muhammad-shiekh-mr4 {
  width: 10rem;
  text-align: center;
}
.text-col-filter {
  filter: brightness(0) saturate(100%) invert(30%) sepia(100%) saturate(500%)
    hue-rotate(320deg);
  mix-blend-mode: multiply;
}
.rectangle-39635-gTE {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 0.8rem;
  margin-bottom: 1rem;
}

.group-1261154791-onk {
  width: 2.8rem;
  position: relative;
  left: -2.3rem;
  top: 4.2rem;
}
/* 
.group-1261154747-XCx .my-zwaara-5kG {
  font-size: 3rem;
  font-weight: 600;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
} */

/* .group-1261154747-XCx .all-your-health-related-information-is-here-your-test-results-summary-of-previous-appointments-and-medical-reports-you-can-review-the-summaries-of-yours-and-your-dependents-whenever-you-want-in-your-zwaara-account-oRN {
  font-size: 1.6rem;
  font-weight: 400;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
} */



.my-wallet-TQp .auto-group-ori4-usi {
  /* margin: 0rem 8rem 10rem 8rem; */
  /* height: 78.1rem; */
  display: flex;
  justify-content: space-between;
}

.my-wallet-TQp .auto-group-ori4-usi .auto-group-xyng-pzg .group-1261154812-MUp {
  /* margin-bottom: 3.05rem; */
}

/* .group-1261154812-MUp .frame-1261154254-Vqv {
  margin-bottom: 1.6rem;
  padding: 1.8rem 0rem 1.85rem 0rem;
  display: flex;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  border-radius: 1.2rem;
} */

.frame-1261154254-Vqv .image-87-obi {
  height: 7.1rem;
}

.fram-37119-9xQQ {
  padding: 3.5rem 2.064rem 3.555rem 3.6rem;
  display: flex;
  align-items: center;
  background-color: rgba(175, 34, 69, 0.1000000015);
  border-radius: 1rem;
}

.group-1261154759-HHv {
  margin-right: 2.0666rem;
  width: 2.8rem;
}

/* .update-color {
  color: #07a29e;
} */

.blood-donation-DSU {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.my-wallet-TQp .auto-group-ori4-usi .auto-group-xyng-pzg .group-1261154919-pBN {
  margin-left: -0.1rem;
  /* width: 13.5001rem; */
  padding: 17px 0px 17px 35px;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  border-radius: 13px;
}

.auto-group-r48k-MBJ {
  margin: 0rem 0rem 2.2rem 0rem;
  display: flex;
  align-items: center;
}

.my-wallet-TQp
  .auto-group-ori4-usi
  .auto-group-xyng-pzg
  .group-1261154919-pBN
  .auto-group-r48k-MBJ
  .group-1261154891-5NC {
  margin: 0rem 2.4rem 0.098rem 0rem;
  width: 2rem;
}

.my-wallet-TQp .auto-group-ori4-usi .group-1261154869-2s66 {
  /* border: solid 0.05rem #89898b; */
  background-color: #ffffff;
  border-radius: 2rem;
  padding: 1rem;
  margin-left: 3%;
  /* width: 50%; */
}

.my-wallet-TQp
  .auto-group-ori4-usi
  .group-1261154869-2s66
  .group-1261154838-YqS {
  display: flex;
  align-items: center;
}

.my-wallet-TQp
  .auto-group-ori4-usi
  .group-1261154869-2s66
  .group-1261154838-YqS
  .group-1261154072-Sfv {
  margin-right: 1.6rem;
  width: 2.7598rem;
}

.my-wallet-TQp .auto-group-ori4-usi .group-1261154869-2s66 .wallet-VPJ {
  margin: 2rem 0rem 1.5rem 0rem;
}

.my-wallet-TQp
  .auto-group-ori4-usi
  .group-1261154869-2s6
  .terms-conditions-bSL {
  margin-top: 1.3rem;
}


.my-wallet-TQp
  .auto-group-ori4-usi
  .group-1261154869-2s6
  .group-1261154870-2Lc {
  padding: 0.7rem 2.4rem;
  margin: 1rem 0 1rem 1rem;
  border: solid 0.05rem #af2245;
  border-radius: 0.4rem;
}

.my-wallet-TQp
  .auto-group-ori4-usi
  .group-1261154869-2s66
  .auto-group-ee92-feU {
  display: flex;
  margin-top: 3rem;
  /* margin-bottom: 3rem; */
}

/* .my-wallet-TQp
  .auto-group-ori4-usi
  .group-1261154869-2s6
  .auto-group-ee92-feU
  .auto-group-dnfe-oEt {
  margin-right: 2rem;
  padding-top: 0.8rem;
  width: 10.4rem;
  height: 11rem;
  background-color: #af2245;
  border-radius: 0.6rem;
  align-items: center;
  display: flex;
  flex-direction: column;
} */


.my-wallet-TQp
  .auto-group-ori4-usi
  .group-1261154869-2s66
  .auto-group-ee92-feU
  .group-1261154873-Rfa {
  margin: 0.5rem 0;
  padding: 0.9rem 2rem 2.7rem 2rem;
  /* width: 52.6rem; */
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.1000000015);
  background-color: #ffffff;
  border-radius: 0.9rem;
}

.my-wallet-TQp
  .auto-group-ori4-usi
  .group-1261154869-2s66
  .auto-group-ee92-feU
  .group-1261154873-Rfa
  .auto-group-i1oz-8Zz {
  display: flex;
  /* justify-content: space-between; */
}
/* .my-wallet-TQp .auto-group-ori4-usi .group-1261154869-2s6 .auto-group-ee92-feU .group-1261154873-Rfa .auto-group-i1oz-8Zz .expired-Utk {
  display: flex;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
} */

/* .my-wallet-TQp .auto-group-ori4-usi .group-1261154869-2s6 .auto-group-ee92-feU .group-1261154873-Rfa .auto-group-i1oz-8Zz .transaction-id-d-12436383-cEG {
  color: #888b9e;
} */

/* .item-10000-3Tz {
  margin-left: 23.4rem;
  margin-top: 0.5rem;
  background: linear-gradient(125.93deg, #af2245 11%, #602d8a 40%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
} */

/* .expiry-date-21-november-2022-aRJ {
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: space-between;
} */

.item-10000-wv4 {
  margin-top: 0.5rem;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.wallet-transaction-expired {
  margin-top: 2rem;
  margin-right: 5.5rem;
}

/* .my-wallet-TQp .group-1000002072-z7e {
  box-sizing: border-box;
  padding: 6.083rem 26.6rem 6.7rem 10rem;
  width: 100%;
  height: 33.8rem;
  display: flex;
  align-items: center;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .group-1261154099-JPE {
  margin: 0.617rem 22.767rem 1.733rem 0rem;
  height: calc(100% - 2.35rem);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .group-1261154099-JPE .group-1261154093-EGt {
  margin-bottom: 2.3rem;
  width: 12.8rem;
  height: 12.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .group-1261154099-JPE .group-1261154040-YoN {
  margin-left: 0.05rem;
  width: 20.2667rem;
  height: 4.1667rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-t8wn-52c {
  margin-right: 8.85rem;
  width: 12.8166rem;
  height: 100%;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-t8wn-52c .company-cHS {
  margin-bottom: 2.1167rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-t8wn-52c .frame-37135-9YG {
  margin-left: 0.0166rem;
  width: calc(100% - 0.0166rem);
  row-gap: 1.2rem;
  display: flex;
  flex-direction: column;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-t8wn-52c .frame-37135-9YG .home-UKe {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-t8wn-52c .frame-37135-9YG .about-p8c {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-t8wn-52c .frame-37135-9YG .join-as-provider-MeL {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-t8wn-52c .frame-37135-9YG .get-care-JJg {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-t8wn-52c .frame-37135-9YG .join-us-edS {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-noy8-C9A {
  margin: 0rem 12.617rem 3.3rem 0rem;
  width: 9.8rem;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-noy8-C9A .laboratory-82p {
  margin-bottom: 2.1167rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-noy8-C9A .frame-37136-U6g {
  width: 100%;
  row-gap: 1.3rem;
  display: flex;
  flex-direction: column;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-noy8-C9A .frame-37136-U6g .general-test-DKA {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-noy8-C9A .frame-37136-U6g .blood-test-AEQ {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-noy8-C9A .frame-37136-U6g .urine-test-JbW {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-noy8-C9A .frame-37136-U6g .dna-test-TDW {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-shcq-bac {
  margin-bottom: 7.8rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-shcq-bac .contact-us-MJt {
  margin-bottom: 2.2167rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-shcq-bac .item-923489934721-61a {
  margin: 0rem 0rem 0.8rem 0.033rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-shcq-bac .zwaarahealthcom-CqJ {
  margin: 0rem 0rem 0.9rem 0.033rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.my-wallet-TQp .group-1000002072-z7e .auto-group-shcq-bac .riadh-2343-saudi-arabia-vmJ {
  margin-left: 0.0334rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
} */

.headingpopup {
  margin-left: 20px;
  text-align: left;
  color: #af2245;
  font-weight: bold;
}

.headingsecond {
  margin-left: 20px;
  color: #111535;
  margin-top: 20px;
  font-weight: bold;
}

.list {
  margin-top: 10px;
  font-family: poppiens, sans-serif;
  font-style: normal;
  font-size: medium;
}


@media screen and (max-width: 600px) {
  .my-wallet-TQp .auto-group-ori4-usi .group-1261154869-2s66 {
    padding: 0rem;
    margin-left: 0%;
}
}