.accordion-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    border: none;
    padding: 10px;
}

.accordion-icon {
    width: 16px;
    height: 16px;
    border: solid #111535;
    border-width: 0 2px 2px 0;
    display: inline-block;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
}

.accordion-button.expanded .accordion-icon {
    transform: rotate(-135deg);
}
#myAccordion{
    margin-bottom: 60px;
}

@media screen and (max-width: 600px) {
    .faq-box-padd{
        padding: 1.6rem;
    }
    #myAccordion{
        margin-bottom: 10px;
    }
}
