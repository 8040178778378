.join-as-provider .frame-1261154266 {
  margin-bottom: 90px;
  background-image: linear-gradient(to right, #d8ebe3, #e0c1da);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0 39px 0;
}

.join-as-provider .banner-container-text {
  text-align: center;
  width: 85%;
  margin: 7rem 0;
  background: linear-gradient(90deg, #af2245, #882767, #602d8a);
  color: var(--gradient, transparent);
  background-clip: text;
  -webkit-background-clip: text;
}

.Ellipse-img {
  margin-left: -5rem;
}

.how-to-join {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-text-container {
  position: relative;
  top: -32rem;
  left: -7rem;
}

.image-text-pro {
  width: 5rem;
  position: relative;
  top: -43rem;
}

.image-text-pro-2 {
  width: 4rem;
  position: relative;
  top: -75rem;
  left: 37rem;
}

.image-text-container-1 {
  position: relative;
  top: -64rem;
  left: 30rem;
}

.image-text-container-2 {
  background-color: #ffffff;
  position: relative;
  top: -44rem;
  left: 20rem;
  /* width: 20rem; */
  height: 7rem;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image-text-pro-3 {
  margin: 0;
}

.container-14-img {
  background-color: #f3f3f3;
  display: flex;
  padding: 1.5rem 1.5rem;
  gap: 1.5rem;
}
/* .advantage-img{
  margin-top: 2rem;
} */

.join-as-provider .group-1261155553 {
  margin-right: 20px;
}

.join-as-provider .trusted-by-the-top-health-cares-of-the-country {
  margin: 39px 0;
}

.join-as-provider .container-17 {
  display: flex;
  align-items: center;
}

.join-as-provider .how-to-join-as-aprovider {
  margin-bottom: 3.5rem;
  background: linear-gradient(90deg, #af2245, #882767, #602d8a);
  color: var(--gradient, transparent);
  background-clip: text;
  -webkit-background-clip: text;
}

.join-as-provider .undraw-approve-qwp-71 {
  margin: 0 0.9px 20px 0;
}

.join-as-provider .img-sub-title {
  text-align: center;
  width: 24.5rem;
  background: linear-gradient(90deg, #af2245, #882767, #602d8a);
  color: var(--gradient, transparent);
  background-clip: text;
  -webkit-background-clip: text;
}

.join-as-provider .group-1261155556 {
  text-align: center;
}

.join-as-provider .vector-1 {
  position: relative;
  top: -30px;
}

.join-as-provider .container-3 {
  margin: 0 0 100px 0px;
  display: flex;
}

.join-as-provider .doctor-specialist-cont {
  margin: 0 45px 30px 0;
}

.join-as-provider .clinic-house-hospital-svgrepo-com-1 {
  /* margin: 9px 45.9px 21.4px 0; */
  margin: 9px 26.9px 21.4px 0;
  width: 58.1px;
  height: 55.6px;
}

.join-as-provider .heading-5 {
  margin-bottom: 15px;
  color: #002a56;
}

.join-as-provider .group-1261155567 {
  margin: 0 0 26.4px 3px;
  display: flex;
}

.join-as-provider .group-1261155550 {
  border-radius: 5px;
  background: linear-gradient(90deg, #af2245, #882767, #602d8a);
  text-align: center;
  padding: 14px 0;
  width: 149px;
  height: 49px;
  border: none;
}

/* .join-as-provider .container-7 {
  margin: 0 7rem 0 1rem;
  width: 60rem;
  display: flex;
  flex-direction: column;
} */

.join-as-provider .rectangle-39711 {
  position: relative;
  top: 120px;
}

.join-as-provider .figure-care-provider-doctors-section-png {
  border-radius: 300px 300px 0 0;
  background: rgba(175, 34, 69, 0.1);
  width: 570px;
  height: 60.5rem;
}

.join-as-provider .heading-41 {
  margin: 0 0px 10px 0px;
}

.join-as-provider .heading-21 {
  margin: 0px 0 11px 0px;
  line-height: 1.1;
}

.join-as-provider .medical-centers-text {
  margin: 0 0px 30px 0px;
}

.join-as-provider .group-1 {
  margin-right: 50px;
}

.join-as-provider .heading-53 {
  margin-bottom: 19px;
}

.join-as-provider .group-1261155560 {
  display: flex;
  flex-direction: column;
}

.join-as-provider .group-1261155574 {
  margin: 0 0px 25px 0px;
  display: flex;
  align-items: center;
}

.join-as-provider .group-3 {
  margin-right: 30px;
}

/* .join-as-provider .group-1261155576 {
  margin: 0 0px 60px 50px;
  width: 60rem;
} */

.join-as-provider .group-1261155563 {
  border-radius: 5px;
  text-align: center;
  background: linear-gradient(90deg, #af2245, #882767, #602d8a);
  padding: 10px 0px;
  margin-top: 3rem;
  width: 149px;
  color: #ffffff;
  border: none;
}

.btn-join {
  margin-top: -5%;
  margin-left: 20%;
}

.join-as-provider .container-14 {
  margin-top: 5rem;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

/* .join-as-provider .container-18 {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.join-as-provider .join-zawaara-now {
  margin-bottom: 2rem;
}

.join-as-provider .group-1261155577 {
  margin-bottom: 24px;
  text-align: center;
}

.join-as-provider .request-sent-svgrepo-com-1 {
  margin-right: 17.4px;
}

.join-as-provider .group-1261155608 {
  border-radius: 8px;
  background: linear-gradient(90deg, #af2245, #882767, #602d8a);
  display: flex;
  align-items: center;
  padding: 15px 30px;
}

.join-as-provider .container-2 {
  background-image: linear-gradient(to right, #d8ebe3, #e0c1da);
  display: flex;
  height: 35rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.join-as-provider .frame-12611542671 {
  margin-top: 40px;
  margin-bottom: 100px;
}

.zwaara-specification {
  background-color: #f3f3f3;
  display: flex;
  justify-content: space-evenly;
  padding: 5rem 0;
  margin-bottom: 8rem;
}

.Booking-details {
  display: flex;
  text-align: center;
}

.img-line-mr {
  margin-right: 10rem;
}

.zwaara-specification {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Booking-details {
  display: flex;
  align-items: center;
  gap: 10px;
}


/* General styles for tablets in portrait mode (min-width: 768px and max-width: 1024px) */
@media (max-width: 1024px) {
  .join-as-provider .frame-1261154266 {
    padding: 10px 0 30px 0;
    margin-bottom: 60px;
  }

  .join-as-provider .banner-container-text {
    width: 95%;
    margin: 5rem 0;
  }

  .join-as-provider .trusted-by-the-top-health-cares-of-the-country {
    margin: 30px 0;
  }

  .join-as-provider .container-17 {
    flex-direction: column;
    align-items: center;
  }

  .join-as-provider .container-3 {
    align-items: center;
    margin: 0 0 80px 0;
  }

  .join-as-provider .container-3 img {
    max-width: 100px;
  }

  .img-sub-title.zw_18 {
    font-size: 14px;
    /* width: 15rem; */
  }

  /* .join-as-provider .container-7 {
    width: calc(100% - 344px);
  } */
  .join-as-provider .figure-care-provider-doctors-section-png {
    width: 344px;
    height: 42rem;
  }

  .join-as-provider .rectangle-39711 {
    width: 100%;
    top: 143px;
  }

  .image-text-container-1 {
    top: -46rem;
    left: 11.5rem;
  }

  .image-text-pro-2 {
    top: -57rem;
    left: 19rem;
  }

  .image-text-container-2 {
    left: 0;
    right: 0;
    margin: auto;
    top: -39rem;
  }

  /* .container-14-img {
    width: 50%;
  } */

  .container-14-img img {
    width: 100%;
  }

  .join-as-provider .group-1261155576 {
    width: 50%;
  }

  /* .join-as-provider .frame-12611542671 {
    margin: 40px 0px 100px 0px;
  } */

  .joinBtnCont {
    display: flex;
    text-decoration: none;
  }

  /* .join-as-provider .doctor-specialist-cont {
    margin: 0 30px 20px 0;
  }

  .join-as-provider .group-1261155550,
  .join-as-provider .group-1261155563 {
    width: 120px;
    padding: 10px 0;
  }

  .join-as-provider .container-7,
  .join-as-provider .group-1261155576 {

  }

  .join-as-provider .figure-care-provider-doctors-section-png {
    width: 100%;
    height: auto;
    border-radius: 0;
  }

  .join-as-provider .image-text-pro,
  .join-as-provider .image-text-pro-2,
  .join-as-provider .image-text-pro-3 {
    position: relative;
    top: -5rem;
  }

  .join-as-provider .container-14-img {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
  }

  .join-as-provider .container-14 {
    margin-top: 3rem;
    flex-direction: column;
    align-items: center;
  }

  .join-as-provider .container-18 {
    align-items: center;
  }

  .join-as-provider .container-2 {
    height: auto;
    padding: 2rem 0;
  }

  .zwaara-specification {
    flex-direction: column;
    align-items: center;
    padding: 3rem 1rem;
  } */
  .join-as-provider .figure-care-provider-doctors-section-png {
    margin: auto;
  }
}
@media (max-width: 820px) {
  .img-sub-title.zw_18 {
    font-size: 14px;
    width: 15rem;
  }
}

@media (max-width: 600px) {
  .join-as-provider .banner-container-text {
    font-size: 32px;
  }

  .join-as-provider .group-1261155553 {
    margin-right: 1px;
  }

  .Ellipsesize {
    width: 9.8rem;
  }

  .join-as-provider .trusted-by-the-top-health-cares-of-the-country {
    margin: 30px 12px;
  }

  .join-as-provider .vector-1 {
    display: none;
  }

  .img-sub-title.zw_18 {
    font-size: 14px;
    /* width: 12rem; */
  }

  .join-as-provider .figure-care-provider-doctors-section-png {
    margin: auto;
  }

  .container-14-img {
    width: 100%;
  }

  .join-as-provider .group-1261155576 {
    width: 100%;
  }

  .zwaara-specification {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 1.3rem;

  }

  .Ellipse-img {
    margin-left: -6rem;
  }

  .join-as-provider .trusted-country {
    margin: 30px 14px;
  }

  .join-as-provider .vector-1 {
    display: none;
  }

  .join-as-provider .container-3 {
    flex-direction: column;
  }

  .img-line-mr {
    margin-right: 0rem;
  }

  .join-as-provider .container-3 {
    margin: 0 0 5px 0;
  }

  .join-as-provider .container-7 {
    padding: 2rem;
    /* text-align: center; */
  }

  .join-as-provider .container-7 .join-heading-4 {
    text-align: center;
  }

  .image-text-container {
    left: -3rem;
  }

  .image-text-pro {
    left: 37px;
  }

  .join-as-provider .container-14 {
    padding: 1.9rem;
    margin-top: 3rem;
  }

  .join-as-provider .container-2 {
    margin: 1.3rem;
    padding: 2rem;
  }

  .join-as-provider .frame-12611542671 {
    margin-top: 24px;
    margin-bottom: 31px;
  }

  .join-as-provider .doctor-specialist-cont {
    margin: 11px 0px 30px 0;
    text-align: center;
  }
}