.date-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.picker-group {
  display: flex;
}

.vue-scroll-picker {
  /* border: 1px solid #ccc; */
  margin: 10px;
  overflow-y: scroll;
  height: 84px;
  width: 80px;
  position: relative;
}

.vue-scroll-picker-rotator {
  position: absolute;
}

.vue-scroll-picker-item {
  text-align: center;
  line-height: 28px;
  /* Assuming each item's height is 40px */
}

.container .btnn {
  background: none;
  border: none;
}
.container .btnn.active {
  background: white;
  color: black;
  border-radius: 6px;
}

.datepickerCont {
  max-height: 15rem;
  margin: 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.mbsc-windows.mbsc-scroller-wheel-line {
  background-color: #f7e9ec !important; /* Change this to your desired color */
  color: #fff !important; /* Change text color if needed */
}

.date-selector {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 90%;
}

.date-selector select {
  flex: 1;
  padding: 15px;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
  font-size: 16px;
}


.date-picker-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  position: relative;
}

.scroll-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 150px;
  width: 30%;
  text-align: center;
  position: relative;
  scrollbar-width: none;
  scrollbar-color: #ccc transparent;
  scroll-behavior: smooth; /* Smooth scrolling */
}

.scroll-container::-webkit-scrollbar {
  width: 6px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-item {
  padding: 21px 0; /* Ensure consistent height */
  text-align: center;
  cursor: pointer;
  color: #999;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.scroll-item.active {
  color: black;
  font-weight: bold;
}

.fixed-center-box {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 50px; /* Matches scroll-item height + padding */
  background-color: rgba(175, 34, 69, 0.1); /* Highlighted area */
  pointer-events: none;
  z-index: 1;
  transform: translateY(-50%);
  border-radius: 10px;
}
