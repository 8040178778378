.auto-group-dxnl-WMASS {
  /* padding: 2rem 1rem 3rem 1rem; */
}
.pati-details {
  padding: 1.366rem 1.67rem 1.639rem 1.707rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
  border-radius: 1.0243rem;
}

.recent-appoi {
  padding: 1.366rem 1.67rem 1.639rem 1.707rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
  border-radius: 1.0243rem;
  margin-top: 10%;
}

.telme-bg {
  background-color: rgba(194, 31, 72, 0.096);
  border-radius: 1.0243rem;
}
.text-black-filter {
  filter: brightness(0) saturate(100%);
}
.text-col-filter {
  filter: brightness(0) saturate(100%) invert(30%) sepia(100%) saturate(500%)
    hue-rotate(320deg);
  mix-blend-mode: multiply;
}

.record-modal .modal-dialog{
  /* margin-left: 31rem; */
  margin-top: 8rem;
}
.record-content{
  /* width: 167%; */
}


@media screen and (max-width: 600px) {
  #patientid .zw_Healthpackage .modal-dialog {
    max-width: 100%;
    margin: 20px auto;
}
}
/* @media screen and (max-width: 820px) {
  .record-modal .modal-dialog{
    margin-left: 5rem;
  }
  .record-content{
    width: 131%;
  }
} */