html {
  font-size: 62.5%;
}
* {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
}
input {
  border: none;
}
.desktop-4-p36 {
  width: 100%;
  overflow: hidden;
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
.desktop-4-p36 .auto-group-vzd6-XiC {
  margin-top: 42px;
}
.desktop-4-p36 .auto-group-vzd6-XiC .group-182-qit {
  margin-bottom: 4.3rem;
  width: 100%;
  height: 6.5rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-4-p36 .auto-group-vzd6-XiC .group-182-qit .logo-Nip {
  width: 6.7997rem;
  height: 6.5001rem;
  position: relative;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-4-p36 .auto-group-vzd6-XiC .group-182-qit .auto-group-bnay-KPA {
  box-sizing: border-box;
  padding: 1.2rem 0rem 0.4rem 31.7rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .group-182-qit
  .auto-group-bnay-KPA
  .group-1261154647-due {
  margin: 1.2rem 12.2rem 1.1rem 0rem;
  height: calc(100% - 2.3rem);
  display: flex;
  column-gap: 4.6rem;
  align-items: center;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .group-182-qit
  .auto-group-bnay-KPA
  .group-1261154647-due
  .home-M4x {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .group-182-qit
  .auto-group-bnay-KPA
  .group-1261154647-due
  .about-Ttg {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .group-182-qit
  .auto-group-bnay-KPA
  .group-1261154647-due
  .join-as-provider-muN {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .group-182-qit
  .auto-group-bnay-KPA
  .group-1261154647-due
  .get-care-7CY {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .group-182-qit
  .auto-group-bnay-KPA
  .group-179-eTN {
  margin: 0.1rem 5.7rem 0rem 0rem;
  width: 5.6rem;
  height: 2.6rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .group-182-qit
  .auto-group-bnay-KPA
  .auto-group-hrvc-xyr {
  width: 14.9rem;
  height: 100%;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 0.8888888889;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.desktop-4-p36 .auto-group-vzd6-XiC .group-1261154838-oUg {
  /* margin: 0rem 115.1rem 3rem 0rem; */
  margin-bottom: 10px;
  width: calc(100% - 115.1rem);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .group-1261154838-oUg
  .group-1261154072-XQg {
  margin: 0rem 1.64rem 0.1rem 0rem;
  width: 2.7598rem;
  height: 2.4rem;
  position: relative;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-4-p36 .auto-group-vzd6-XiC .group-1261154838-oUg .back-EZz {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.desktop-4-p36 .auto-group-vzd6-XiC .auto-group-dvg4-y1n .auto-group-z2j6-7Nt {
  margin-right: 3.3243rem;
  width: 36.8757rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .frame-1261154254-SRA {
  box-sizing: border-box;
  padding: 1.7rem 23.876rem 1.787rem 1.6rem;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(175, 34, 69, 0.1000000015);
  border-radius: 1.0243rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .frame-1261154254-SRA
  .rectangle-39635-mCY {
  margin-right: 1.6rem;
  width: 6.5rem;
  height: 6.5rem;
  object-fit: cover;
  vertical-align: top;
  border-radius: 7rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .frame-1261154254-SRA
  .aha-Use {
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt {
  box-sizing: border-box;
  padding-top: 2.4128rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154801-iGC {
  margin-bottom: 2.95rem;
  box-sizing: border-box;
  padding: 3rem 1.9rem 2.9rem 1.9rem;
  width: 34.8rem;
  height: 33.5rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154801-iGC
  .group-1261154793-RRW {
  width: 100%;
  height: 100%;
  row-gap: 1.6rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154801-iGC
  .group-1261154793-RRW
  .auto-group-ymq6-Ym2 {
  box-sizing: border-box;
  padding: 1.55rem 11.8rem 1.55rem 4.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154801-iGC
  .group-1261154793-RRW
  .auto-group-ymq6-Ym2
  .vector-fak {
  margin: 0.1rem 5.2rem 0rem 0rem;
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154801-iGC
  .group-1261154793-RRW
  .auto-group-ymq6-Ym2
  .records-Boz {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154801-iGC
  .group-1261154793-RRW
  .auto-group-htui-J7v {
  box-sizing: border-box;
  padding: 1.6rem 11.6rem 1.5rem 4.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #af2245;
  border-radius: 0.5rem;
  flex-shrink: 0;
}



.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154801-iGC
  .group-1261154793-RRW
  .auto-group-htui-J7v
  .icon-cuJ {
  margin: 0rem 4.9rem 0rem 0rem;
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154801-iGC
  .group-1261154793-RRW
  .auto-group-htui-J7v
  .personal-Ynx {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154801-iGC
  .group-1261154793-RRW
  .auto-group-oqka-52C {
  box-sizing: border-box;
  padding: 1.55rem 11.9rem 1.55rem 4.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154801-iGC
  .group-1261154793-RRW
  .auto-group-oqka-52C
  .group-1261154885-oTz {
  margin: 0.1rem 5.3rem 0rem 0rem;
  width: 2.4rem;
  height: 2.4rem;
  position: relative;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154801-iGC
  .group-1261154793-RRW
  .auto-group-oqka-52C
  .medical-X96 {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154801-iGC
  .group-1261154793-RRW
  .auto-group-umic-FKz {
  box-sizing: border-box;
  padding: 1.5rem 11.6rem 1.6rem 4.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154801-iGC
  .group-1261154793-RRW
  .auto-group-umic-FKz
  .vector-n52 {
  margin: 0.2rem 4.9rem 0rem 0rem;
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154801-iGC
  .group-1261154793-RRW
  .auto-group-umic-FKz
  .life-style-ttk {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .my-zwrrar-PKi {
  margin-bottom: 2.15rem;
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154808-iMz {
  width: 34.8rem;
  height: 12.4rem;
  position: relative;
  background-color: rgba(175, 34, 69, 0.1000000015);
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154808-iMz
  .rectangle-39639-Fsi {
  width: 31rem;
  height: 5.7rem;
  position: absolute;
  left: 1.9rem;
  top: 3.3rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154808-iMz
  .my-programs-yoi {
  width: 12rem;
  height: 2.6rem;
  position: absolute;
  left: 13.4rem;
  top: 4.9rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .auto-group-z2j6-7Nt
  .auto-group-4yhs-aQt
  .group-1261154808-iMz
  .group-1261154897-UVa {
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  left: 6.1rem;
  top: 5rem;
  object-fit: contain;
  vertical-align: top;
}
.desktop-4-p36 .auto-group-vzd6-XiC .auto-group-dvg4-y1n .group-1261154803-AtC {
  /* width: calc(100% - 37rem);
  height: 100%;
  position: relative; */

  border-radius: 0.6rem;
  /* flex-shrink: 0; */
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-upgc-VQg {
  width: 66.8rem;
  height: 5.6rem;
  position: absolute;
  left: 1.3rem;
  top: 41.9rem;
  display: flex;
  align-items: center;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-upgc-VQg
  .group-1261154710-pSx {
  margin-right: 3.1rem;
  box-sizing: border-box;
  padding: 0.672rem 1.8rem 0.672rem 0.7rem;
  height: 100%;
  display: flex;
  align-items: center;
  border: solid 0.2rem #af2245;
  background-color: #ffffff;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-upgc-VQg
  .group-1261154710-pSx
  .rectangle-39628-KPi {
  margin-right: 1.1rem;
  width: 5.2rem;
  height: 4.256rem;
  object-fit: cover;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-upgc-VQg
  .group-1261154710-pSx
  .group-1261154880-eRz {
  margin: 1.128rem 0rem 0.528rem 0rem;
  height: calc(100% - 1.656rem);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-upgc-VQg
  .group-1261154710-pSx
  .group-1261154880-eRz
  .item-966-Av8 {
  margin-right: 10.5rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #221f1f;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-upgc-VQg
  .group-1261154710-pSx
  .group-1261154880-eRz
  .vector-spY {
  margin-bottom: 0.72rem;
  width: 2.1rem;
  height: 1.12rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-upgc-VQg
  .group-1261154711-AYk {
  box-sizing: border-box;
  padding: 1.5rem 2.1rem;
  width: 37.6rem;
  height: 100%;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #717171;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  border: solid 0.2rem #af2245;
  background-color: #ffffff;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .view-patient-DG8 {
  width: 10.1rem;
  height: 2.1rem;
  position: absolute;
  left: 1.3rem;
  top: 3.2rem;
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-17ux-7MW {
  width: 81.2rem;
  height: 5.6rem;
  position: absolute;
  left: 1.3rem;
  top: 11.6rem;
  display: flex;
  align-items: center;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-17ux-7MW
  .group-1261154804-ES8 {
  margin-right: 8.6rem;
  width: 36.3rem;
  height: 100%;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-17ux-7MW
  .group-1261154804-ES8
  .rectangle-39640-P48 {
  width: 100%;
  height: 5.6rem;
  border: solid 0.1rem #af2245;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 0.6rem;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-17ux-7MW
  .group-1261154809-gJ8 {
  width: 36.3rem;
  height: 100%;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-17ux-7MW
  .group-1261154809-gJ8
  .rectangle-39640-qRv {
  width: 100%;
  height: 5.6rem;
  border: solid 0.1rem #af2245;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 0.6rem;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-iwgc-9Sc {
  width: 81.2rem;
  height: 5.6rem;
  position: absolute;
  left: 1.3rem;
  top: 21.7rem;
  display: flex;
  align-items: center;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-iwgc-9Sc
  .group-1261154810-UUt {
  margin-right: 8.6rem;
  width: 36.3rem;
  height: 5.6rem;
  object-fit: contain;
  vertical-align: top;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-iwgc-9Sc
  .group-1261154811-zy2 {
  box-sizing: border-box;
  padding: 2.3rem 2.563rem 2.3rem 32rem;
  height: 100%;
  border: solid 0.1rem #af2245;
  background-color: #ffffff;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-iwgc-9Sc
  .group-1261154811-zy2
  .group-1261154857-8pL {
  width: 1.7373rem;
  height: 1rem;
  object-fit: contain;
  vertical-align: top;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-aida-eGt {
  width: 81.2rem;
  height: 5.6rem;
  position: absolute;
  left: 1.3rem;
  top: 31.8rem;
  display: flex;
  align-items: center;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-aida-eGt
  .group-1261154812-aRS {
  margin-right: 8.6rem;
  width: 36.3rem;
  height: 100%;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-aida-eGt
  .group-1261154812-aRS
  .rectangle-39640-8Sx {
  width: 100%;
  height: 5.6rem;
  border: solid 0.1rem #af2245;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 0.6rem;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-aida-eGt
  .group-1261154813-ew6 {
  width: 36.3rem;
  height: 100%;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-aida-eGt
  .group-1261154813-ew6
  .rectangle-39640-1me {
  width: 100%;
  height: 5.6rem;
  border: solid 0.1rem #af2245;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 0.6rem;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-hbbj-97A {
  width: 81.2rem;
  height: 5.6rem;
  position: absolute;
  left: 1.3rem;
  top: 75.2rem;
  display: flex;
  align-items: center;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-hbbj-97A
  .auto-group-7vbz-frC {
  margin-right: 8.6rem;
  box-sizing: border-box;
  padding: 1.6rem 1.6rem 1.6rem 31.3rem;
  height: 100%;
  display: flex;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  border: solid 0.1rem #af2245;
  background-color: #ffffff;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-hbbj-97A
  .auto-group-tt8p-Kfr {
  box-sizing: border-box;
  padding: 1.6rem 1.6rem 1.6rem 32.6rem;
  height: 100%;
  display: flex;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  border: solid 0.1rem #af2245;
  background-color: #ffffff;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-gbqw-npL {
  width: 36.3rem;
  height: 5.6rem;
  position: absolute;
  left: 24rem;
  top: 83.2rem;
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  border: solid 0.1rem #8e8e90;
  box-sizing: border-box;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
  border-radius: 0.6rem;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-2qgy-Due {
  width: 54.4rem;
  height: 2.65rem;
  position: absolute;
  left: 1.3rem;
  top: 8rem;
  display: flex;
  align-items: center;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-2qgy-Due
  .name-keg {
  margin: 0.05rem 39.4rem 0rem 0rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-2qgy-Due
  .last-name-GN8 {
  margin-bottom: 0.05rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-q14y-mpg {
  width: 51.6rem;
  height: 2.6rem;
  position: absolute;
  left: 1.3rem;
  top: 18.15rem;
  display: flex;
  align-items: center;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-q14y-mpg
  .relation-JZi {
  margin-right: 37.4rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-q14y-mpg
  .gender-drt {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-5cep-miC {
  width: 48.5rem;
  height: 2.6rem;
  position: absolute;
  left: 1.3rem;
  top: 28.25rem;
  display: flex;
  align-items: center;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-5cep-miC
  .date-of-birth-hbr {
  margin-right: 33.8rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-5cep-miC
  .age-SJY {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .mobile-number-nNQ {
  width: 13.9rem;
  height: 2.6rem;
  position: absolute;
  left: 1.3rem;
  top: 38.35rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-5gcc-5sJ {
  width: 81.2rem;
  height: 9.15rem;
  position: absolute;
  left: 1.3rem;
  top: 48.55rem;
  display: flex;
  align-items: center;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-5gcc-5sJ
  .group-1261154879-Qeg {
  margin-right: 8.6rem;
  width: 36.3rem;
  height: 100%;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-5gcc-5sJ
  .group-1261154879-Qeg
  .id-iqama-number-9sA {
  margin-bottom: 0.95rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-5gcc-5sJ
  .group-1261154879-Qeg
  .rectangle-39640-HyN {
  width: 100%;
  height: 5.6rem;
  border: solid 0.1rem #af2245;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 0.6rem;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-5gcc-5sJ
  .group-1261154878-DME {
  width: 36.3rem;
  height: 100%;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-5gcc-5sJ
  .group-1261154878-DME
  .nationality-mde {
  margin-bottom: 0.95rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-5gcc-5sJ
  .group-1261154878-DME
  .group-1261154815-W5S {
  width: 100%;
  border-radius: 0.6rem;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-5gcc-5sJ
  .group-1261154878-DME
  .group-1261154815-W5S
  .rectangle-39640-TWU {
  width: 100%;
  height: 5.6rem;
  border: solid 0.1rem #af2245;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 0.6rem;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-v8kw-aLC {
  width: 68.6rem;
  height: 3.2rem;
  position: absolute;
  left: 1.3rem;
  top: 64.7rem;
  display: flex;
  align-items: center;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-v8kw-aLC
  .auto-group-kcku-JGC {
  box-sizing: border-box;
  padding-right: 2.8rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-v8kw-aLC
  .auto-group-kcku-JGC
  .marital-status-SdJ {
  margin-right: 34rem;
  display: flex;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-v8kw-aLC
  .auto-group-kcku-JGC
  .radio-button-AJQ {
  margin-right: 1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-v8kw-aLC
  .auto-group-kcku-JGC
  .married-Vba {
  margin-top: 0.1rem;
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-v8kw-aLC
  .group-1261154863-DnU {
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-v8kw-aLC
  .group-1261154863-DnU
  .radio-button-NfN {
  margin-right: 1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-v8kw-aLC
  .group-1261154863-DnU
  .single-KKi {
  display: flex;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .additional-information-Fz4 {
  width: 18.5rem;
  height: 2.1rem;
  position: absolute;
  left: 1.3rem;
  top: 61rem;
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-pftr-y9N {
  width: 50.8rem;
  height: 2.1rem;
  position: absolute;
  left: 1.3rem;
  top: 71.5rem;
  display: flex;
  align-items: center;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-pftr-y9N
  .height-WQC {
  margin-right: 39.5rem;
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .auto-group-vzd6-XiC
  .auto-group-dvg4-y1n
  .group-1261154803-AtC
  .auto-group-pftr-y9N
  .weight-qSU {
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36 .group-1000002072-9TA {
  box-sizing: border-box;
  padding: 6.083rem 26.6rem 6.7rem 10rem;
  width: 100%;
  height: 33.8rem;
  display: flex;
  align-items: center;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
  flex-shrink: 0;
}
.desktop-4-p36 .group-1000002072-9TA .group-1261154099-TTr {
  margin: 0.617rem 22.767rem 1.733rem 0rem;
  height: calc(100% - 2.35rem);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.desktop-4-p36
  .group-1000002072-9TA
  .group-1261154099-TTr
  .group-1261154093-B8x {
  margin-bottom: 2.3rem;
  width: 12.8rem;
  height: 12.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-4-p36
  .group-1000002072-9TA
  .group-1261154099-TTr
  .group-1261154040-hd6 {
  margin-left: 0.05rem;
  width: 20.2667rem;
  height: 4.1667rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-4-p36 .group-1000002072-9TA .auto-group-5jsa-2vG {
  margin-right: 8.85rem;
  width: 12.8166rem;
  height: 100%;
  flex-shrink: 0;
}
.desktop-4-p36 .group-1000002072-9TA .auto-group-5jsa-2vG .company-yKi {
  margin-bottom: 2.1167rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.desktop-4-p36 .group-1000002072-9TA .auto-group-5jsa-2vG .frame-37135-Jsn {
  margin-left: 0.0166rem;
  width: calc(100% - 0.0166rem);
  row-gap: 1.2rem;
  display: flex;
  flex-direction: column;
}
.desktop-4-p36
  .group-1000002072-9TA
  .auto-group-5jsa-2vG
  .frame-37135-Jsn
  .home-1GQ {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .group-1000002072-9TA
  .auto-group-5jsa-2vG
  .frame-37135-Jsn
  .about-M5N {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .group-1000002072-9TA
  .auto-group-5jsa-2vG
  .frame-37135-Jsn
  .join-as-provider-gtL {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .group-1000002072-9TA
  .auto-group-5jsa-2vG
  .frame-37135-Jsn
  .get-care-dYg {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .group-1000002072-9TA
  .auto-group-5jsa-2vG
  .frame-37135-Jsn
  .join-us-NWG {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36 .group-1000002072-9TA .auto-group-e1ac-i4L {
  margin: 0rem 12.617rem 3.3rem 0rem;
  width: 9.8rem;
  flex-shrink: 0;
}
.desktop-4-p36 .group-1000002072-9TA .auto-group-e1ac-i4L .laboratory-Sm2 {
  margin-bottom: 2.1167rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.desktop-4-p36 .group-1000002072-9TA .auto-group-e1ac-i4L .frame-37136-acL {
  width: 100%;
  row-gap: 1.3rem;
  display: flex;
  flex-direction: column;
}
.desktop-4-p36
  .group-1000002072-9TA
  .auto-group-e1ac-i4L
  .frame-37136-acL
  .general-test-8Nx {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .group-1000002072-9TA
  .auto-group-e1ac-i4L
  .frame-37136-acL
  .blood-test-UBv {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .group-1000002072-9TA
  .auto-group-e1ac-i4L
  .frame-37136-acL
  .urine-test-1xY {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .group-1000002072-9TA
  .auto-group-e1ac-i4L
  .frame-37136-acL
  .dna-test-ZjA {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36 .group-1000002072-9TA .auto-group-tu9n-6yz {
  margin-bottom: 7.8rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.desktop-4-p36 .group-1000002072-9TA .auto-group-tu9n-6yz .contact-us-3uE {
  margin-bottom: 2.2167rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .group-1000002072-9TA
  .auto-group-tu9n-6yz
  .item-923489934721-PCQ {
  margin: 0rem 0rem 0.8rem 0.033rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36 .group-1000002072-9TA .auto-group-tu9n-6yz .zwaarahealthcom-6Mi {
  margin: 0rem 0rem 0.9rem 0.033rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-4-p36
  .group-1000002072-9TA
  .auto-group-tu9n-6yz
  .riadh-2343-saudi-arabia-p2p {
  margin-left: 0.0334rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
/* .contact-form {
  height: auto;
  width: auto;
  padding: 4rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
} */

.contact-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.contact-form p {
  text-align: center;
  margin-bottom: 20px;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  /* border: 1px solid #af2245; */
  border-radius: 5px;
  box-sizing: border-box;
}

.contact-form button {
  width: 100%;
  border: none;
}
.contact-form1 {
  max-width: 1000px; /* Increase form width */
  margin: auto; /* Center the form */
  padding: 20px;
  border: 1px solid #af2245;
  border-radius: 10px;
  background-color: rgba(175, 34, 69, 0.1000000015);
}
select#gender {
  width: 100%;
  padding: 8px 12px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  line-height: 1.5;
  border: 1px solid #af2245;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
}
.inputborder {
  width: 100%;
  padding: 8px 12px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  line-height: 1.5;
  border: 1px solid #af2245;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
}

/* @media only screen and (max-width: 1024px) {
  
  .desktop-4-p36
    .auto-group-vzd6-XiC
    .auto-group-dvg4-y1n
    .group-1261154803-AtC {
    width: 57.8rem;
  }
} */
@media only screen and (max-width: 820px) {
  /* .desktop-4-p36
    .auto-group-vzd6-XiC
    .auto-group-dvg4-y1n
    .group-1261154803-AtC {
    width: 37.8rem;
  } */
  .desktop-4-p36 .link_btn {
    width: 33.5rem;
  }
}

@media only screen and (max-width: 768px) {
  /* .desktop-4-p36
    .auto-group-vzd6-XiC
    .auto-group-dvg4-y1n
    .group-1261154803-AtC {
    width: 32.8rem;
  } */
  .desktop-4-p36 .link_btn {
    width: 28.5rem;
  }
}
