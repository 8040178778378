.group-h1e a {
  text-decoration: none;
}

.frame-1261154258-qVE {
  width: 300px;
}

.group-dAC img {
  width: 22px;
  margin-left: 28px;
  margin-bottom: 5px;
}

.text-black-filter {
  filter: brightness (0) saturate (100%);
}

.group-AJY img {
  width: 23px;
  margin-left: 20px;
  margin-bottom: 5px;
}

.group-uQY img {
  width: 26px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.group-h1e {
  display: flex;
  justify-content: space-around;
}

.line-4-DeC {
  height: 39px;
}

.blood-donation {
  margin-left: 20px;
  width: 300px;
  display: flex;
  justify-content: space-between;
}

.auto-group-9asv-tpt {
  margin-bottom: 5rem;
  /* height: 29.5rem; */
  /* position: relative; */
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
}

.auto-group-9asv-tpt .frame-1261154258-qVE {
  /* box-sizing: border-box; */
  padding: 1.2rem 1.796rem 0.759rem 1.6rem;
  /* position: absolute;
  left: 25.9184rem;
  top: 20rem; */
  background-color: #ffffff;
  border-radius: 0.5rem;
  /* margin-left: 18%;
  margin-top: -5rem; */
}



.auto-group-9asv-tpt .group-1261154818-4we .group-1261154816-bAt {
  /* margin: 1.3rem 3.4rem 0rem 0rem;
  width: 10rem; */
}

.auto-group-9asv-tpt .group-1261154818-4we .group-1261154816-bAt .muhammad-shiekh-7f2 {
  /* position: absolute; */
  /* top: 10.1rem; */
  /* text-align: center; */
  margin-top: -2rem;
  margin-left: 4px;
}

.auto-group-9asv-tpt .group-1261154818-4we .group-1261154816-bAt .rectangle-39635-2X6 {
  width: 9.9rem;
  height: 9.5rem;
  object-fit: cover;
  border-radius: 0.8rem;
}

.outimage {
  width: 9.9rem;
  height: 9.5rem;
  /* object-fit: cover; */
  border-radius: 0.8rem;
  border: 0.5px solid rgba(202, 201, 201, 0.582);
}

.auto-group-9asv-tpt .group-1261154818-4we .group-1261154816-bAt .group-1261154791-ZG8 {
  width: 2.8rem;
  position: relative;
  left: 8rem;
  top: -2rem;
  cursor: pointer;
}

.auto-group-znxc-BB2 {
  /* margin: 0rem 10rem 1.2rem 10rem; */
  /* height: 54rem; */
  /* position: relative; */
  /* display: flex;
  justify-content: space-between; */
  /* margin-top: 20px; */
  margin-bottom: 20px;
}

.auto-group-znxc-BB2 .group-1261154921-tLL {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.auto-group-znxc-BB2 .group-1261154921-tLL .frame-1261154254-KwS {
  margin: 1.6rem 0.024rem 1.713rem 0rem;
  box-sizing: border-box;
  padding: 1.366rem 1.67rem 1.639rem 1.707rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
  border-radius: 1.0243rem;
  min-width: 32rem;
}

.auto-group-znxc-BB2 .group-1261154921-tLL .frame-1261154254-KwS .auto-group-dugu-Dmv {
  display: flex;
  justify-content: space-between;
}

.auto-group-znxc-BB2 .group-1261154921-tLL .frame-1261154254-KwS .auto-group-dugu-Dmv .group-1261154764-wxp {
  /* margin-right: 16.5597rem; */
  width: 4.0973rem;
  height: 4.0973rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}

.auto-group-znxc-BB2 .group-1261154921-tLL .frame-1261154254-KwS .auto-group-pbgg-bXa {
  margin-right: 0.1053rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: flex-end;
  flex-shrink: 0;
}

.firends-9Z6 {
  margin-right: 21.9883rem;
  display: flex;
}

.auto-group-znxc-BB2 .group-1261154921-tLL .frame-1261154254-KwS .auto-group-pbgg-bXa .group-5he {
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  margin: 0rem 0.7rem 0.6rem 0rem;
  width: 1.5706rem;
}

.auto-group-znxc-BB2 .group-1261154920-Uv8 {
  /* width: 124rem; */
}

.auto-group-znxc-BB2 .auto-group-x1vc-RaU {
  margin-bottom: 2.6rem;
  /* width: 100%; */
  /* height: 11rem; */
  /* display: flex; */
}

.auto-group-znxc-BB2 .auto-group-x1vc-RaU .frame-1261154254-xKW {
  /* margin-right: 62.7rem; */
  /* width: 43rem; */
  padding-top: 15px;
  padding-bottom: 15px;
  /* height: 100%; */
  display: flex;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  border-radius: 1.2rem;
}

.auto-group-znxc-BB2 .auto-group-x1vc-RaU .frame-1261154254-xKW .image-87-rvg {
  height: 7.1rem;
}

.pro-addrecord {
  display: flex;
  justify-content: end;
}

.auto-group-znxc-BB2 .group-1261154834-4fN {
  padding: 1.5rem 2.7rem 1.4rem 2.9rem;
  width: 18.1rem;
  height: 4.9rem;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
  border-radius: 0.5rem;
  /* margin-left: 14rem; */
  /* flex-shrink: 0; */
}

.auto-group-znxc-BB2 .group-1261154834-4fN .frame-1261154256-z3E {
  height: 100%;
  display: flex;
  align-items: center;
}

.auto-group-znxc-BB2 .group-1261154834-4fN .frame-1261154256-z3E .component-2-LMz {
  margin-right: 1.1rem;
  width: 1.9998rem;
}

.auto-group-znxc-BB2 .group-1261154920-Uv8 .group-1261154811-Mnt {
  /* width: 43.2rem; */
  /* height: 10rem; */
  padding: 30px 1px;
  border-radius: 1.0694rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
  display: flex;
  align-items: center;
}

.group-e1J {
  margin-left: 2rem;
  width: 2.8rem;
}

.auto-group-znxc-BB2 .group-1261155016-Atk {
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  border-radius: 1.0694rem;
  padding: 2.45rem 3.1rem 3.148rem 3.1rem;
  /* width: 43.2rem; */
  /* position: absolute; */
  top: 26.8rem;
}

.auto-group-znxc-BB2 .group-1261155016-Atk .frame-1261154262-5Vv .group-1261155539-RZn {
  margin: 0rem 2.3rem 1.602rem 0rem;
  display: flex;
  align-items: center;
}

.hover-profile:hover {
  cursor: pointer;
  color: #af2245;
}

.group-1261154891-kc4 {
  width: 2rem;
  margin-right: 24px;
}

.auto-group-znxc-BB2 .frame-1261154256-yHn {
  padding: 1.4rem 1.6rem 1.1rem 2rem;
  width: 43.1rem;
  height: 10.1rem;
  /* position: absolute; */
  left: 7.4rem;
  top: 43.9rem;
  display: flex;
  flex-direction: column;
  border: solid 0.1rem #af2245;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  background-color: #ffffff;
  border-radius: 1.2rem;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  min-height: 300px !important;
}

.profile_update {
  height: 40px;
  width: 100%;
  margin: 8px;
}

.profile_update_lable {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 10px;
  background-color: #af22451a;
}

.profile_update_input {
  margin-right: 5px;
  height: 25px;
  width: 25px;
  border: 2px solid #a39f9fda;
  appearance: none;
  /* Hides the default radio button */
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
}

.profile_update_input:checked {
  background-color: #af2245;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  min-height: 300px !important;
}

#addpati .modal-content {
  width: 80rem !important;
  margin-left: -14rem;
  margin-top: 10rem;
  border-radius: 20px;
}

.addpattientsecond .modal-content {
  margin-top: 10rem;
}

.addpattientsecond .modal-header {
  border: none;
}

#exampleModalcountry .sps-dialog-close i {
  color: #777777;
  border: 1px solid #777777;
  padding: 5px;
  font-size: 8px;
}

/* Hide the default arrow */
.menu-flags .flag-select__arrow {
  display: none !important;
}

/* Position and style the custom arrow */
.custom-flags-select {
  position: relative;
}

.custom-flags-select .custom-arrow-icon {
  font-size: 25px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  background-color: #fff;
}

@media (max-width: 820px) {
  .auto-group-znxc-BB2 {
    margin: 2%;
  }

  /* .auto-group-znxc-BB2 .group-1261154921-tLL .frame-1261154254-KwS {
    width: 28rem;
  } */

  #addpati .modal-content {
    width: 77rem !important;
    /* margin-left: -14rem;
      margin-top: 10rem; */
  }

  .add-patient-oKS .group-1261154829-vzp .group-1261154828-VwS {
    /* box-sizing: border-box;
    padding: 3.3rem 1.8rem 2.4rem 3.2rem;
    width: 66.8rem;
    height: 95.2rem; */
    position: absolute;
    left: 4.2rem !important;
    /* top: 8rem;
    border: solid 0.2rem #848486;
    border-radius: 0.6rem; */
  }

  .auto-group-znxc-BB2 .group-1261154920-Uv8 .group-1261154811-Mnt {
    padding-right: 10px;
  }
}

@media (max-width: 768px) {
  #addpati .modal-content {
    width: 73rem !important;
    margin-left: -12rem;
    /* margin-top: 10rem; */
  }

  .add-patient-oKS .group-1261154829-vzp .group-1261154828-VwS {
    position: absolute;
    left: 2rem !important;
  }

}


@media (max-width: 600px) {
  .auto-group-9asv-tpt .group-1261154818-4we .group-1261154816-bAt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .auto-group-9asv-tpt .group-1261154818-4we .group-1261154816-bAt .group-1261154791-ZG8 {
    width: 2.8rem;
    position: relative;
    left: 5rem;
    top: -2rem;
    cursor: pointer;
  }

  .pro-addrecord {
    justify-content: start;
    margin-bottom: 15px;
  }

  .auto-group-znxc-BB2 .group-1261154921-tLL {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .auto-group-znxc-BB2 .group-1261154920-Uv8 .group-1261154811-Mnt {
    padding-right: 10px;
  }

  .auto-group-9asv-tpt .group-1261154818-4we {
    padding-left: 10px;
    padding-right: 10px;
  }

  .auto-group-9asv-tpt .frame-1261154258-qVE {
    width: 100%;
  }

  .auto-group-znxc-BB2 {
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-top: 0px;
  }

  .auto-group-9asv-tpt {
    margin-bottom: 2rem;
  }

  .auto-group-znxc-BB2 .group-1261154921-tLL .frame-1261154254-KwS {
    width: 100%;
  }

  .auto-group-znxc-BB2 .group-1261154920-Uv8 .group-1261154811-Mnt {
    padding: 15px 1px;
    padding-right: 18px;

  }
}