html {
  font-size: 62.5%;
}
* {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
}
input {
  border: none;
}
.desktop-5-vEY {
  width: 100%;
  overflow: hidden;
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
.desktop-5-vEY .auto-group-i5de-DUY {
  /* box-sizing: border-box;
  padding: 2.4rem 10rem 15rem 10rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0; */
  margin-top: 55px;
}
.desktop-5-vEY .auto-group-i5de-DUY .group-182-7pp {
  margin-bottom: 4.3rem;
  width: 100%;
  height: 6.5rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-5-vEY .auto-group-i5de-DUY .group-182-7pp .logo-Fg8 {
  width: 6.7997rem;
  height: 6.5001rem;
  position: relative;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY .auto-group-i5de-DUY .group-182-7pp .auto-group-ahac-ztc {
  box-sizing: border-box;
  padding: 1.2rem 0rem 0.4rem 31.7rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .group-182-7pp
  .auto-group-ahac-ztc
  .group-1261154647-j5W {
  margin: 1.2rem 12.2rem 1.1rem 0rem;
  height: calc(100% - 2.3rem);
  display: flex;
  column-gap: 4.6rem;
  align-items: center;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .group-182-7pp
  .auto-group-ahac-ztc
  .group-1261154647-j5W
  .home-EHA {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .group-182-7pp
  .auto-group-ahac-ztc
  .group-1261154647-j5W
  .about-kmJ {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .group-182-7pp
  .auto-group-ahac-ztc
  .group-1261154647-j5W
  .join-as-provider-gur {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .group-182-7pp
  .auto-group-ahac-ztc
  .group-1261154647-j5W
  .get-care-S8L {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .group-182-7pp
  .auto-group-ahac-ztc
  .group-179-ye4 {
  margin: 0.1rem 5.7rem 0rem 0rem;
  width: 5.6rem;
  height: 2.6rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .group-182-7pp
  .auto-group-ahac-ztc
  .auto-group-pk4u-JAY {
  width: 14.9rem;
  height: 100%;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 0.8888888889;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.desktop-5-vEY .auto-group-i5de-DUY .group-1261154838-vhi {
  /* margin: 0rem 115.1rem 3rem 0rem; */
  margin-bottom: 10px;
  width: calc(100% - 115.1rem);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .group-1261154838-vhi
  .group-1261154072-3GY {
  margin: 0rem 1.64rem 0.1rem 0rem;
  width: 2.7598rem;
  height: 2.4rem;
  position: relative;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY .auto-group-i5de-DUY .group-1261154838-vhi .back-ZEt {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY .auto-group-i5de-DUY .auto-group-8tme-hM6 {
  /* width: 100%;
  height: 101rem;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0; */
}
.desktop-5-vEY .auto-group-i5de-DUY .auto-group-8tme-hM6 .auto-group-gj4g-rDz {
  margin-right: 2.3243rem;
  width: 36.8757rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .frame-1261154254-C2x {
  box-sizing: border-box;
  padding: 1.7rem 14.976rem 1.787rem 1.6rem;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(175, 34, 69, 0.1000000015);
  border-radius: 1.0243rem;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .frame-1261154254-C2x
  .rectangle-39635-uxx {
  margin-right: 1.6rem;
  width: 6.5rem;
  height: 6.5rem;
  object-fit: cover;
  vertical-align: top;
  border-radius: 7rem;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .frame-1261154254-C2x
  .noha-abdullah-de4 {
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #000000;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue {
  box-sizing: border-box;
  padding-top: 2.4128rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154801-VgG {
  margin-bottom: 2.95rem;
  box-sizing: border-box;
  padding: 3rem 1.9rem 2.9rem 1.9rem;
  width: 34.8rem;
  height: 33.5rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154801-VgG
  .group-1261154793-bzC {
  width: 100%;
  height: 100%;
  row-gap: 1.6rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154801-VgG
  .group-1261154793-bzC
  .auto-group-zfs2-k6Q {
  box-sizing: border-box;
  padding: 1.55rem 11.8rem 1.55rem 4.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154801-VgG
  .group-1261154793-bzC
  .auto-group-zfs2-k6Q
  .vector-For {
  margin: 0.1rem 5.2rem 0rem 0rem;
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154801-VgG
  .group-1261154793-bzC
  .auto-group-zfs2-k6Q
  .records-mnC {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154801-VgG
  .group-1261154793-bzC
  .auto-group-wngk-Gyr {
  box-sizing: border-box;
  padding: 1.6rem 11.6rem 1.5rem 4.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154801-VgG
  .group-1261154793-bzC
  .auto-group-wngk-Gyr
  .icon-QaG {
  margin: 0rem 4.9rem 0rem 0rem;
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154801-VgG
  .group-1261154793-bzC
  .auto-group-wngk-Gyr
  .personal-jsS {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154801-VgG
  .group-1261154793-bzC
  .auto-group-xx8y-sTr {
  box-sizing: border-box;
  padding: 1.55rem 11.9rem 1.55rem 4.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #af2245;
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154801-VgG
  .group-1261154793-bzC
  .auto-group-xx8y-sTr
  .group-1261154885-oMW {
  margin: 0.1rem 5.3rem 0rem 0rem;
  width: 2.4rem;
  height: 2.4rem;
  position: relative;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154801-VgG
  .group-1261154793-bzC
  .auto-group-xx8y-sTr
  .medical-XYQ {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154801-VgG
  .group-1261154793-bzC
  .auto-group-jeyr-TBA {
  box-sizing: border-box;
  padding: 1.5rem 11.6rem 1.6rem 4.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154801-VgG
  .group-1261154793-bzC
  .auto-group-jeyr-TBA
  .vector-zB6 {
  margin-right: 4.9rem;
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154801-VgG
  .group-1261154793-bzC
  .auto-group-jeyr-TBA
  .life-style-KjA {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .my-zwrrar-E5S {
  margin-bottom: 2.15rem;
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #000000;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154808-Mfr {
  width: 34.8rem;
  height: 12.4rem;
  position: relative;
  background-color: rgba(175, 34, 69, 0.1000000015);
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154808-Mfr
  .rectangle-39639-tvg {
  width: 31rem;
  height: 5.7rem;
  position: absolute;
  left: 1.9rem;
  top: 3.3rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154808-Mfr
  .my-programs-RQp {
  width: 12rem;
  height: 2.6rem;
  position: absolute;
  left: 13.4rem;
  top: 4.9rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .auto-group-gj4g-rDz
  .auto-group-qfhw-wue
  .group-1261154808-Mfr
  .group-1261154897-XTr {
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  left: 6.1rem;
  top: 5rem;
  object-fit: contain;
  vertical-align: top;
}
.desktop-5-vEY .auto-group-i5de-DUY .auto-group-8tme-hM6 .group-1261154855-pxk {
  /* box-sizing: border-box; */
  padding: 1.8rem 3.9rem 2.1rem 3.1rem;
  /* width: calc(100% - 37rem);
  height: 100%; */
  background-color: rgba(175, 34, 69, 0.1000000015);
  /* flex-shrink: 0; */
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY {
  /* width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; */
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .view-patient-Jsv {
  margin-bottom: 2.4rem;
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154853-RxY {
  margin-bottom: 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154853-RxY
  .injuries-Bgp {
  margin-bottom: 1rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154853-RxY
  .group-1261154804-igk {
  /* box-sizing: border-box; */
  padding: 1.6rem 2.8rem 1.5rem 2.8rem;
  /* width: 100%;
  height: 10.5rem; */
  border: solid 0.1rem #af2245;
  background-color: #ffffff;
  border-radius: 0.6rem;
  /* flex-shrink: 0; */
}
/* .desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154853-RxY
  .group-1261154804-igk
  .group-1261154844-Fgg {
  width: 32.4rem;
  height: 100%;
} */
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154853-RxY
  .group-1261154804-igk
  .group-1261154844-Fgg
  .have-you-had-any-injuries-the-past-CM2 {
  margin-bottom: 1.5rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154853-RxY
  .group-1261154804-igk
  .group-1261154844-Fgg
  .auto-group-h2q4-KAk {
row-gap: 14px;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154853-RxY
  .group-1261154804-igk
  .group-1261154844-Fgg
  .auto-group-h2q4-KAk
  .group-1261154843-eyi {
  /* margin: 0.1rem 5.2rem 0rem 0rem; */
  display: flex;
  align-items: center;
  /* flex-shrink: 0; */
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154853-RxY
  .group-1261154804-igk
  .group-1261154844-Fgg
  .auto-group-h2q4-KAk
  .group-1261154843-eyi
  .radio-button-zXn {
  margin-right: 1.5rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154853-RxY
  .group-1261154804-igk
  .group-1261154844-Fgg
  .auto-group-h2q4-KAk
  .group-1261154843-eyi
  .yes-88C {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154853-RxY
  .group-1261154804-igk
  .group-1261154844-Fgg
  .auto-group-h2q4-KAk
  .group-1261154842-SPn {
  margin-bottom: 0.1rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154853-RxY
  .group-1261154804-igk
  .group-1261154844-Fgg
  .auto-group-h2q4-KAk
  .group-1261154842-SPn
  .radio-button-PZv {
  margin-right: 1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154853-RxY
  .group-1261154804-igk
  .group-1261154844-Fgg
  .auto-group-h2q4-KAk
  .group-1261154842-SPn
  .no-XRE {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154848-2Mz {
  margin-bottom: 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154848-2Mz
  .past-medication-Nwe {
  margin-bottom: 1rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154848-2Mz
  .group-1261154804-uwa {
  box-sizing: border-box;
  padding: 1.6rem 2.8rem 1.5rem 2.8rem;
  width: 100%;
  height: 10.5rem;
  border: solid 0.1rem #af2245;
  background-color: #ffffff;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154848-2Mz
  .group-1261154804-uwa
  .group-1261154844-ePN {
  width: 39.3rem;
  height: 100%;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154848-2Mz
  .group-1261154804-uwa
  .group-1261154844-ePN
  .have-you-been-on-medications-in-the-past-anp {
  margin-bottom: 1.5rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154848-2Mz
  .group-1261154804-uwa
  .group-1261154844-ePN
  .auto-group-vncl-hcY {
  margin-right: 18.9rem;
  width: calc(100% - 18.9rem);
  display: flex;
  align-items: center;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154848-2Mz
  .group-1261154804-uwa
  .group-1261154844-ePN
  .auto-group-vncl-hcY
  .group-1261154843-3gQ {
  margin: 0.1rem 5.2rem 0rem 0rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154848-2Mz
  .group-1261154804-uwa
  .group-1261154844-ePN
  .auto-group-vncl-hcY
  .group-1261154843-3gQ
  .radio-button-awE {
  margin-right: 1.5rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154848-2Mz
  .group-1261154804-uwa
  .group-1261154844-ePN
  .auto-group-vncl-hcY
  .group-1261154843-3gQ
  .yes-8Sx {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154848-2Mz
  .group-1261154804-uwa
  .group-1261154844-ePN
  .auto-group-vncl-hcY
  .group-1261154842-UWp {
  margin-bottom: 0.1rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154848-2Mz
  .group-1261154804-uwa
  .group-1261154844-ePN
  .auto-group-vncl-hcY
  .group-1261154842-UWp
  .radio-button-2YL {
  margin-right: 1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154848-2Mz
  .group-1261154804-uwa
  .group-1261154844-ePN
  .auto-group-vncl-hcY
  .group-1261154842-UWp
  .no-yTa {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154849-Uv8 {
  margin-bottom: 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154849-Uv8
  .allergies-EPW {
  margin-bottom: 1rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154849-Uv8
  .group-1261154804-Zgg {
  box-sizing: border-box;
  padding: 1.6rem 2.8rem 1.5rem 2.8rem;
  width: 100%;
  height: 10.5rem;
  border: solid 0.1rem #af2245;
  background-color: #ffffff;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154849-Uv8
  .group-1261154804-Zgg
  .group-1261154844-J8U {
  width: 26rem;
  height: 100%;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154849-Uv8
  .group-1261154804-Zgg
  .group-1261154844-J8U
  .are-you-allergic-to-anything-EXv {
  margin-bottom: 1.5rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154849-Uv8
  .group-1261154804-Zgg
  .group-1261154844-J8U
  .auto-group-vyfe-xTv {
  margin-right: 5.6rem;
  width: calc(100% - 5.6rem);
  display: flex;
  align-items: center;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154849-Uv8
  .group-1261154804-Zgg
  .group-1261154844-J8U
  .auto-group-vyfe-xTv
  .group-1261154843-hwJ {
  margin: 0.1rem 5.2rem 0rem 0rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154849-Uv8
  .group-1261154804-Zgg
  .group-1261154844-J8U
  .auto-group-vyfe-xTv
  .group-1261154843-hwJ
  .radio-button-Stt {
  margin-right: 1.5rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154849-Uv8
  .group-1261154804-Zgg
  .group-1261154844-J8U
  .auto-group-vyfe-xTv
  .group-1261154843-hwJ
  .yes-zQc {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154849-Uv8
  .group-1261154804-Zgg
  .group-1261154844-J8U
  .auto-group-vyfe-xTv
  .group-1261154842-jd6 {
  margin-bottom: 0.1rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154849-Uv8
  .group-1261154804-Zgg
  .group-1261154844-J8U
  .auto-group-vyfe-xTv
  .group-1261154842-jd6
  .radio-button-tkt {
  margin-right: 1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154849-Uv8
  .group-1261154804-Zgg
  .group-1261154844-J8U
  .auto-group-vyfe-xTv
  .group-1261154842-jd6
  .no-3Nt {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154850-GmS {
  margin-bottom: 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154850-GmS
  .chronic-diseases-omN {
  margin-bottom: 1rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154850-GmS
  .group-1261154804-wcg {
  box-sizing: border-box;
  padding: 1.6rem 2.8rem 1.5rem 2.8rem;
  width: 100%;
  height: 10.5rem;
  border: solid 0.1rem #af2245;
  background-color: #ffffff;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154850-GmS
  .group-1261154804-wcg
  .group-1261154844-foa {
  width: 32rem;
  height: 100%;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154850-GmS
  .group-1261154804-wcg
  .group-1261154844-foa
  .do-you-have-any-chronic-diseases-1sS {
  margin-bottom: 1.5rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154850-GmS
  .group-1261154804-wcg
  .group-1261154844-foa
  .auto-group-akjc-YcU {
  margin-right: 11.6rem;
  width: calc(100% - 11.6rem);
  display: flex;
  align-items: center;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154850-GmS
  .group-1261154804-wcg
  .group-1261154844-foa
  .auto-group-akjc-YcU
  .group-1261154843-J5r {
  margin: 0.1rem 5.2rem 0rem 0rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154850-GmS
  .group-1261154804-wcg
  .group-1261154844-foa
  .auto-group-akjc-YcU
  .group-1261154843-J5r
  .radio-button-qLg {
  margin-right: 1.5rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154850-GmS
  .group-1261154804-wcg
  .group-1261154844-foa
  .auto-group-akjc-YcU
  .group-1261154843-J5r
  .yes-ap4 {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154850-GmS
  .group-1261154804-wcg
  .group-1261154844-foa
  .auto-group-akjc-YcU
  .group-1261154842-XjJ {
  margin-bottom: 0.1rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154850-GmS
  .group-1261154804-wcg
  .group-1261154844-foa
  .auto-group-akjc-YcU
  .group-1261154842-XjJ
  .radio-button-61i {
  margin-right: 1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154850-GmS
  .group-1261154804-wcg
  .group-1261154844-foa
  .auto-group-akjc-YcU
  .group-1261154842-XjJ
  .no-ENp {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154851-Y8c {
  margin-bottom: 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154851-Y8c
  .current-medication-Hrt {
  margin-bottom: 1rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154851-Y8c
  .group-1261154804-DkY {
  box-sizing: border-box;
  padding: 1.6rem 2.8rem 1.5rem 2.8rem;
  width: 100%;
  height: 10.5rem;
  border: solid 0.1rem #af2245;
  background-color: #ffffff;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154851-Y8c
  .group-1261154804-DkY
  .group-1261154844-9eC {
  width: 42rem;
  height: 100%;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154851-Y8c
  .group-1261154804-DkY
  .group-1261154844-9eC
  .are-you-taking-any-medicines-at-the-moment-trg {
  margin-bottom: 1.5rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154851-Y8c
  .group-1261154804-DkY
  .group-1261154844-9eC
  .auto-group-ofwt-pEY {
  margin-right: 21.6rem;
  width: calc(100% - 21.6rem);
  display: flex;
  align-items: center;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154851-Y8c
  .group-1261154804-DkY
  .group-1261154844-9eC
  .auto-group-ofwt-pEY
  .group-1261154843-ktt {
  margin: 0.1rem 5.2rem 0rem 0rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154851-Y8c
  .group-1261154804-DkY
  .group-1261154844-9eC
  .auto-group-ofwt-pEY
  .group-1261154843-ktt
  .radio-button-6C4 {
  margin-right: 1.5rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154851-Y8c
  .group-1261154804-DkY
  .group-1261154844-9eC
  .auto-group-ofwt-pEY
  .group-1261154843-ktt
  .yes-EZA {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154851-Y8c
  .group-1261154804-DkY
  .group-1261154844-9eC
  .auto-group-ofwt-pEY
  .group-1261154842-NfN {
  margin-bottom: 0.1rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154851-Y8c
  .group-1261154804-DkY
  .group-1261154844-9eC
  .auto-group-ofwt-pEY
  .group-1261154842-NfN
  .radio-button-8Pe {
  margin-right: 1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154851-Y8c
  .group-1261154804-DkY
  .group-1261154844-9eC
  .auto-group-ofwt-pEY
  .group-1261154842-NfN
  .no-fuN {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154852-mhW {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154852-mhW
  .surgeries-v4c {
  margin-bottom: 1rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154852-mhW
  .group-1261154804-F6t {
  box-sizing: border-box;
  padding: 1.6rem 2.8rem 1.5rem 2.8rem;
  width: 100%;
  height: 10.5rem;
  border: solid 0.1rem #af2245;
  background-color: #ffffff;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154852-mhW
  .group-1261154804-F6t
  .group-1261154844-Le8 {
  width: 28.8rem;
  height: 100%;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154852-mhW
  .group-1261154804-F6t
  .group-1261154844-Le8
  .did-you-undergo-any-surgeries-4a8 {
  margin-bottom: 1.5rem;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154852-mhW
  .group-1261154804-F6t
  .group-1261154844-Le8
  .auto-group-nfec-ANG {
  margin-right: 8.4rem;
  width: calc(100% - 8.4rem);
  display: flex;
  align-items: center;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154852-mhW
  .group-1261154804-F6t
  .group-1261154844-Le8
  .auto-group-nfec-ANG
  .group-1261154843-Hhn {
  margin: 0.1rem 5.2rem 0rem 0rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154852-mhW
  .group-1261154804-F6t
  .group-1261154844-Le8
  .auto-group-nfec-ANG
  .group-1261154843-Hhn
  .radio-button-CJx {
  margin-right: 1.5rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154852-mhW
  .group-1261154804-F6t
  .group-1261154844-Le8
  .auto-group-nfec-ANG
  .group-1261154843-Hhn
  .yes-KuN {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154852-mhW
  .group-1261154804-F6t
  .group-1261154844-Le8
  .auto-group-nfec-ANG
  .group-1261154842-UGU {
  margin-bottom: 0.1rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154852-mhW
  .group-1261154804-F6t
  .group-1261154844-Le8
  .auto-group-nfec-ANG
  .group-1261154842-UGU
  .radio-button-d9N {
  margin-right: 1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .auto-group-i5de-DUY
  .auto-group-8tme-hM6
  .group-1261154855-pxk
  .group-1261154854-ZQY
  .group-1261154852-mhW
  .group-1261154804-F6t
  .group-1261154844-Le8
  .auto-group-nfec-ANG
  .group-1261154842-UGU
  .no-xhS {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY .group-1000002072-E9A {
  box-sizing: border-box;
  padding: 6.083rem 26.6rem 6.7rem 10rem;
  width: 100%;
  height: 33.8rem;
  display: flex;
  align-items: center;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
  flex-shrink: 0;
}
.desktop-5-vEY .group-1000002072-E9A .group-1261154099-wpG {
  margin: 0.617rem 22.767rem 1.733rem 0rem;
  height: calc(100% - 2.35rem);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.desktop-5-vEY
  .group-1000002072-E9A
  .group-1261154099-wpG
  .group-1261154093-Fpx {
  margin-bottom: 2.3rem;
  width: 12.8rem;
  height: 12.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY
  .group-1000002072-E9A
  .group-1261154099-wpG
  .group-1261154040-b88 {
  margin-left: 0.05rem;
  width: 20.2667rem;
  height: 4.1667rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.desktop-5-vEY .group-1000002072-E9A .auto-group-zurg-vRJ {
  margin-right: 8.85rem;
  width: 12.8166rem;
  height: 100%;
  flex-shrink: 0;
}
.desktop-5-vEY .group-1000002072-E9A .auto-group-zurg-vRJ .company-UBv {
  margin-bottom: 2.1167rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.desktop-5-vEY .group-1000002072-E9A .auto-group-zurg-vRJ .frame-37135-QLU {
  margin-left: 0.0166rem;
  width: calc(100% - 0.0166rem);
  row-gap: 1.2rem;
  display: flex;
  flex-direction: column;
}
.desktop-5-vEY
  .group-1000002072-E9A
  .auto-group-zurg-vRJ
  .frame-37135-QLU
  .home-irx {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .group-1000002072-E9A
  .auto-group-zurg-vRJ
  .frame-37135-QLU
  .about-4fv {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .group-1000002072-E9A
  .auto-group-zurg-vRJ
  .frame-37135-QLU
  .join-as-provider-D32 {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .group-1000002072-E9A
  .auto-group-zurg-vRJ
  .frame-37135-QLU
  .get-care-9hN {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .group-1000002072-E9A
  .auto-group-zurg-vRJ
  .frame-37135-QLU
  .join-us-hyn {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY .group-1000002072-E9A .auto-group-nm9n-FkQ {
  margin: 0rem 12.617rem 3.3rem 0rem;
  width: 9.8rem;
  flex-shrink: 0;
}
.desktop-5-vEY .group-1000002072-E9A .auto-group-nm9n-FkQ .laboratory-PLp {
  margin-bottom: 2.1167rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.desktop-5-vEY .group-1000002072-E9A .auto-group-nm9n-FkQ .frame-37136-8ZJ {
  width: 100%;
  row-gap: 1.3rem;
  display: flex;
  flex-direction: column;
}
.desktop-5-vEY
  .group-1000002072-E9A
  .auto-group-nm9n-FkQ
  .frame-37136-8ZJ
  .general-test-5De {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .group-1000002072-E9A
  .auto-group-nm9n-FkQ
  .frame-37136-8ZJ
  .blood-test-D4x {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .group-1000002072-E9A
  .auto-group-nm9n-FkQ
  .frame-37136-8ZJ
  .urine-test-ZPi {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .group-1000002072-E9A
  .auto-group-nm9n-FkQ
  .frame-37136-8ZJ
  .dna-test-uyN {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY .group-1000002072-E9A .auto-group-qqks-G3E {
  margin-bottom: 7.8rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.desktop-5-vEY .group-1000002072-E9A .auto-group-qqks-G3E .contact-us-cMz {
  margin-bottom: 2.2167rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2562500238;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .group-1000002072-E9A
  .auto-group-qqks-G3E
  .item-923489934721-9Mv {
  margin: 0rem 0rem 0.8rem 0.033rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY .group-1000002072-E9A .auto-group-qqks-G3E .zwaarahealthcom-TdW {
  margin: 0rem 0rem 0.9rem 0.033rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.desktop-5-vEY
  .group-1000002072-E9A
  .auto-group-qqks-G3E
  .riadh-2343-saudi-arabia-ay2 {
  margin-left: 0.0334rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}


.radiobtnicon {
  border: 2px solid #ccc; /* Default border color */
  -webkit-appearance: none; /* Remove default radio button appearance */
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
}

.radiobtnicon:checked {
  border-color: #AF2245;
}

.radiobtnicon:checked::before {
  content: '';
  width: 15px;
  height: 15px;
  background-color: #AF2245;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


@media only screen and (max-width: 1024px) {
  .desktop-5-vEY .auto-group-i5de-DUY {
    /* padding: 2.4rem 10rem 15rem 2rem; */
  }
  .desktop-5-vEY
    .auto-group-i5de-DUY
    .auto-group-8tme-hM6
    .group-1261154855-pxk {
    /* width: 58.8rem; */
  }
}
@media only screen and (max-width: 820px) {
  .desktop-5-vEY
    .auto-group-i5de-DUY
    .auto-group-8tme-hM6
    .group-1261154855-pxk {
    /* width: 39.8rem;
    padding: 1.8rem 2rem 2.1rem 2rem; */
  }
  .desktop-5-vEY
    .auto-group-i5de-DUY
    .auto-group-8tme-hM6
    .group-1261154855-pxk
    .group-1261154854-ZQY
    .group-1261154853-RxY
    .group-1261154804-igk
    .group-1261154844-Fgg
    .have-you-had-any-injuries-the-past-CM2 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .desktop-5-vEY
    .auto-group-i5de-DUY
    .auto-group-8tme-hM6
    .group-1261154855-pxk {
    /* width: 34rem; */
    /* padding: 1.8rem 0.5rem 2.1rem 1rem; */
  }
  .desktop-5-vEY
    .auto-group-i5de-DUY
    .auto-group-8tme-hM6
    .group-1261154855-pxk
    .group-1261154854-ZQY
    .group-1261154853-RxY
    .group-1261154804-igk
    .group-1261154844-Fgg
    .have-you-had-any-injuries-the-past-CM2 {
    font-size: 13px;
  }
  .desktop-5-vEY
    .auto-group-i5de-DUY
    .auto-group-8tme-hM6
    .group-1261154855-pxk
    .group-1261154854-ZQY
    .group-1261154853-RxY
    .group-1261154804-igk {
    padding: 1.6rem 2.8rem 1.5rem 1rem;
  }


}
