html {
  font-size: 62.5%;
}
* {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
}
input {
  border: none;
  width: 180px;
}

.payment-details-toe {
  width: 100%;
  height: 90.8rem;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
}
.payment-details-toe .group-182-cje {
  width: 124rem;
  height: 6.5rem;
  position: absolute;
  left: 10rem;
  top: 2.4rem;
  display: flex;
  align-items: center;
}
.payment-details-toe .group-182-cje .logo-jJU {
  width: 6.7997rem;
  height: 6.5001rem;
  position: relative;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.payment-details-toe .group-182-cje .auto-group-t7qg-UWx {
  box-sizing: border-box;
  padding: 1.2rem 0rem 0.4rem 31.7rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.payment-details-toe .group-182-cje .auto-group-t7qg-UWx .group-10-Q9i {
  margin: 1.2rem 12.2rem 1.1rem 0rem;
  height: calc(100% - 2.3rem);
  display: flex;
  column-gap: 4.6rem;
  align-items: center;
  flex-shrink: 0;
}
.payment-details-toe
  .group-182-cje
  .auto-group-t7qg-UWx
  .group-10-Q9i
  .home-ucG {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.payment-details-toe
  .group-182-cje
  .auto-group-t7qg-UWx
  .group-10-Q9i
  .about-e44 {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.payment-details-toe
  .group-182-cje
  .auto-group-t7qg-UWx
  .group-10-Q9i
  .join-as-provider-ayJ {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.payment-details-toe
  .group-182-cje
  .auto-group-t7qg-UWx
  .group-10-Q9i
  .get-care-7yE {
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.4444444444;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.payment-details-toe .group-182-cje .auto-group-t7qg-UWx .group-179-U36 {
  margin: 0.1rem 5.7rem 0rem 0rem;
  width: 5.6rem;
  height: 2.6rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.payment-details-toe .group-182-cje .auto-group-t7qg-UWx .auto-group-fncc-z1S {
  width: 14.9rem;
  height: 100%;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 0.8888888889;
  color: #ffffff;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
  border-radius: 0.5rem;
  flex-shrink: 0;
}
.payment-details-toe .rectangle-39544-SPE {
  width: 144rem;
  height: 19.2rem;
  position: absolute;
  left: 0;
  top: 12.2rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
}
.payment-details-toe .group-1261154093-mAc {
  width: 85.3rem;
  height: 2.7rem;
  position: absolute;
  left: 10rem;
  top: 14.6rem;
  display: flex;
  align-items: center;
}
.payment-details-toe .group-1261154093-mAc .group-1261154076-Tp8 {
  margin-right: 10.3rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154093-mAc
  .group-1261154076-Tp8
  .group-1261154072-DHW {
  margin: 0rem 1.64rem 0.1rem 0rem;
  width: 2.7599rem;
  height: 2.4rem;
  position: relative;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.payment-details-toe .group-1261154093-mAc .group-1261154076-Tp8 .back-Xoz {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #333333;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.payment-details-toe .group-1261154093-mAc .group-1261154073-rrG {
  margin: 1.21rem 0rem 1.292rem 0rem;
  height: calc(100% - 2.502rem);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154093-mAc
  .group-1261154073-rrG
  .rectangle-39545-b3A {
  margin-right: 1.0913rem;
  width: 15.7066rem;
  height: 0.198rem;
  background-color: #af2245;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154093-mAc
  .group-1261154073-rrG
  .rectangle-39546-Kjr {
  margin-right: 1.0913rem;
  width: 15.7066rem;
  height: 0.198rem;
  background-color: #ffffff;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154093-mAc
  .group-1261154073-rrG
  .rectangle-39547-foi {
  margin-right: 1.0912rem;
  width: 15.7066rem;
  height: 0.198rem;
  background-color: #ffffff;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154093-mAc
  .group-1261154073-rrG
  .rectangle-39548-cU4 {
  width: 15.7066rem;
  height: 0.198rem;
  background-color: #ffffff;
  flex-shrink: 0;
}
.payment-details-toe .frame-37120-xnp {
  box-sizing: border-box;
  padding: 0.8rem 2.4rem;
  width: 45.8rem;
  height: 5.3rem;
  position: absolute;
  left: 10rem;
  top: 21.4rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 1.2rem;
}
.payment-details-toe .frame-37120-xnp .search-your-lab-tests-packages-UFN {
  margin-right: 12.7rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  color: #999999;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.payment-details-toe .frame-37120-xnp .line-1-b56 {
  margin-right: 2.2rem;
  width: 0rem;
  height: 3.7rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.payment-details-toe .frame-37120-xnp .vector-wPr {
  margin-top: 0.1rem;
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.payment-details-toe .group-1261154677-fqe {
  box-sizing: border-box;
  padding: 2rem 3.2rem;
  width: 51.7rem;
  height: 19.2rem;
  position: absolute;
  left: 10rem;
  top: 33.8rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
}
.payment-details-toe .group-1261154677-fqe .group-1261154676-Nzx {
  width: 36.5rem;
  height: 100%;
  display: flex;
  align-items: flex-start;
}
.payment-details-toe
  .group-1261154677-fqe
  .group-1261154676-Nzx
  .group-1261154660-vma {
  margin-right: 2.4rem;
  width: 16.7rem;
  height: 15.2rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154677-fqe
  .group-1261154676-Nzx
  .group-1261154672-rv8 {
  row-gap: 1.6rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154677-fqe
  .group-1261154676-Nzx
  .group-1261154672-rv8
  .zwaara-cPW {
  font-size: 1.605rem;
  font-weight: 600;
  line-height: 1.0177711424;
  text-transform: capitalize;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154677-fqe
  .group-1261154676-Nzx
  .group-1261154672-rv8
  .laboratory-home-visit-Z3r {
  font-size: 1.0052rem;
  font-weight: 700;
  line-height: 1.624999917;
  text-transform: capitalize;
  color: #86909d;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154677-fqe
  .group-1261154676-Nzx
  .group-1261154672-rv8
  .view-selected-details-HVe {
  font-size: 1.605rem;
  font-weight: 600;
  line-height: 1.0177711424;
  text-transform: capitalize;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.payment-details-toe .add-details-c28 {
  width: 18.9rem;
  height: 1.1rem;
  position: absolute;
  left: 66.1rem;
  top: 33.8rem;
  font-size: 3rem;
  font-weight: 600;
  line-height: 0.3350815773;
  text-transform: uppercase;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.payment-details-toe .select-patient-JvY {
  width: 11.6rem;
  height: 1.7rem;
  position: absolute;
  left: 66.1rem;
  top: 38.6rem;
  font-size: 1.605rem;
  font-weight: 600;
  line-height: 1.0177711424;
  text-transform: capitalize;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.payment-details-toe .rectangle-39553-QTn {
  width: 67.9rem;
  height: 22.1rem;
  position: absolute;
  left: 66.1rem;
  top: 42.7rem;
  border: solid 0.1rem #111535;
  box-sizing: border-box;
  border-radius: 0.7rem;
}
.payment-details-toe .rectangle-39611-ijN {
  width: 144rem;
  height: 120.8rem;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(166, 166, 166, 0.6000000238);
}
.payment-details-toe .group-1261154744-FzC {
  /* width: 50.4rem;
  height: 95.1rem; */
  /* position: absolute;
  left: 10.3rem; */
  /* top: 12.9rem; */
}
.payment-details-toe .group-1261154744-FzC .group-1261154722-yfJ {
  width: 79.4rem;
  height: 95.1rem;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: contain;
  vertical-align: top;
}
.payment-details-toe .group-1261154744-FzC .selected-card-add-new-card-7mW {
  width: 33rem;
  height: 2.6rem;
  /* position: absolute;
  left: 3.2rem;
  top: 5rem; */
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.1818181818;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.payment-details-toe .group-1261154744-FzC .group-1261154935-Cnx {
  width: 22.5rem;
  height: 3rem;
  /* position: absolute;
  left: 3.2rem;
  top: 10.1rem; */
  display: flex;
  align-items: center;
}
.payment-details-toe
  .group-1261154744-FzC
  .group-1261154935-Cnx
  .group-1261154937-LPN {
  margin-right: 1.2rem;
  width: 3rem;
  height: 3rem;
  object-fit: contain;
  vertical-align: top;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154744-FzC
  .group-1261154935-Cnx
  .add-new-card-gCL {
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 1;
  color: #af2245;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}

.payment-details-toe .group-1261154744-FzC .group-1261154729-MSt {
  width: 73rem;
  height: 8.4rem;
  position: absolute;
  left: 3.2rem;
  top: 28.1rem;
  border-radius: 0.6rem;
}
.payment-details-toe
  .group-1261154744-FzC
  .group-1261154729-MSt
  .call-number-fyN {
  margin-bottom: 0.8rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.payment-details-toe
  .group-1261154744-FzC
  .group-1261154729-MSt
  .auto-group-joye-DEC {
  box-sizing: border-box;
  padding: 1.2rem 3.2rem;
  width: 100%;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #717171;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  border: solid 0.2rem #d2d2d2;
  border-radius: 0.6rem;
}
.payment-details-toe .group-1261154744-FzC .toggle-button-hfA {
  box-sizing: border-box;
  padding: 0rem 0.1rem 0rem 4.4rem;
  width: 7.7rem;
  height: 3.2rem;
  position: absolute;
  left: 3.2rem;
  top: 50.9rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
  border-radius: 2.6rem;
}
.payment-details-toe
  .group-1261154744-FzC
  .toggle-button-hfA
  .radio-button-D7i {
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  vertical-align: top;
}
.payment-details-toe .group-1261154744-FzC .group-1261154743-Xu6 {
  width: 73rem;
  height: 13.9rem;
  position: absolute;
  left: 3.2rem;
  top: 39.9rem;
  display: flex;
  align-items: flex-start;
  border-radius: 0.6rem;
}
.payment-details-toe
  .group-1261154744-FzC
  .group-1261154743-Xu6
  .group-1261154742-TXr {
  margin-right: 4.7rem;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154744-FzC
  .group-1261154743-Xu6
  .group-1261154742-TXr
  .group-1261154741-CVS {
  margin-right: 1.8rem;
  width: 23.1rem;
  height: 100%;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154744-FzC
  .group-1261154743-Xu6
  .group-1261154742-TXr
  .group-1261154741-CVS
  .group-1261154730-whv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.6rem;
}
.payment-details-toe
  .group-1261154744-FzC
  .group-1261154743-Xu6
  .group-1261154742-TXr
  .group-1261154741-CVS
  .group-1261154730-whv
  .call-number-6ap {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154744-FzC
  .group-1261154743-Xu6
  .group-1261154742-TXr
  .group-1261154741-CVS
  .group-1261154730-whv
  .auto-group-vezg-ES8 {
  margin-bottom: 2.7rem;
  box-sizing: border-box;
  padding: 1.2rem 3.2rem;
  width: 21.7rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #717171;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  border: solid 0.2rem #d2d2d2;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154744-FzC
  .group-1261154743-Xu6
  .group-1261154742-TXr
  .group-1261154741-CVS
  .group-1261154730-whv
  .save-this-card-6jE {
  margin-left: 8.9rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154744-FzC
  .group-1261154743-Xu6
  .group-1261154742-TXr
  .group-1261154739-p9S {
  margin: 3.6rem 0rem 5.3rem 0rem;
  box-sizing: border-box;
  padding: 1.2rem 3.2rem;
  width: 21.7rem;
  height: calc(100% - 8.9rem);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #717171;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  border: solid 0.2rem #d2d2d2;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154744-FzC
  .group-1261154743-Xu6
  .group-1261154740-dcg {
  width: 21.7rem;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.payment-details-toe
  .group-1261154744-FzC
  .group-1261154743-Xu6
  .group-1261154740-dcg
  .cid-cvv-b3i {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
}
.payment-details-toe
  .group-1261154744-FzC
  .group-1261154743-Xu6
  .group-1261154740-dcg
  .auto-group-wzly-vbn {
  box-sizing: border-box;
  padding: 1.2rem 2.2rem;
  width: 100%;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #717171;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  border: solid 0.2rem #d2d2d2;
  border-radius: 0.6rem;
}
.payment-details-toe .group-1261154744-FzC .rectangle-39577-ofa {
  width: 73rem;
  height: 8.8rem;
  position: absolute;
  left: 3.2rem;
  top: 77.6rem;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
  border-radius: 0.5rem;
}

.payment-details-toe .group-1261154744-FzC .image-81-Rwr {
  width: 7.186rem;
  height: 2.4rem;
  /* position: absolute;
  left: 47.3rem;
  top: 10.1rem; */
  object-fit: cover;
  vertical-align: top;
}
.payment-details-toe .group-1261154744-FzC .image-82-ZYG {
  width: 2.4048rem;
  height: 2.4rem;
  position: absolute;
  left: 65.0973rem;
  top: 10.1rem;
  object-fit: cover;
  vertical-align: top;
}
.payment-details-toe .group-1261154744-FzC .image-84-taY {
  width: 7.4113rem;
  height: 2.4rem;
  position: absolute;
  left: 56.0859rem;
  top: 10.1rem;
  object-fit: cover;
  vertical-align: top;
}
.payment-details-toe .group-1261154744-FzC .image-85-pj6 {
  width: 3.065rem;
  height: 2.4rem;
  position: relative;
  left: 69.1021rem;
  top: 10.1rem;
  background-size: 163.132% 148.759%;
  background-position: -0.98rem -0.6501rem;
  /* background-image: url("../assets/image-85.png");  */
}
.payment-details-toe .group-1261154744-FzC .image-86-uVe {
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  left: 73.7672rem;
  top: 10.1rem;
  object-fit: cover;
  vertical-align: top;
}
.switch {
  /* position: relative;
  display: inline-block; */
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider1 {
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 60px;
  height: 32px;
}

.slider1:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider1 {
  background-color: #af2245;
}

input:focus + .slider1 {
  box-shadow: 0 0 1px #af2245;
}

input:checked + .slider1:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider1.round {
  border-radius: 34px;
}

.slider1.round:before {
  border-radius: 50%;
}

/* ============ Akash 09/07/24 ================== */
.add-new-card{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.card-detailsss{
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
  row-gap: 15px;
}

.cid-cvv{
  margin-top: -4rem;
}

.pay-img-size {
  height: 2.4rem;
  object-fit: cover;
  padding: 0 5px;
}
#Paymentdetails .modal-dialogg {
  max-width: 45% !important;
}

.card-name {
  /* max-width: 73rem; */
  /* height: 8.6rem; */
  border-radius: 0.6rem;
}

.card-name .auto-group-m6jy-V9n {
  padding: 1.2rem 3.2rem;
  border: solid 0.2rem #d2d2d2;
  border-radius: 0.6rem;
}

.error-message {
  color: red;
}

.card-details {
  /* max-width: 73rem; */
  /* height: 13.9rem; */
  /* display: flex;
  align-items: flex-start; */
  /* border-radius: 0.6rem; */
}

.card-details .group-1261154742-TXr {
  margin-right: 4.7rem;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.card-details .group-1261154742-TXr .group-1261154741-CVS {
  margin-right: 1.8rem;
  width: 23.1rem;
  height: 100%;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.card-details
  .group-1261154742-TXr
  .group-1261154741-CVS
  .group-1261154730-whv {
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  border-radius: 0.6rem;
}
.card-details
  .group-1261154742-TXr
  .group-1261154741-CVS
  .group-1261154730-whv
  .call-number-6ap {
  margin-bottom: 1rem;
  /* font-size: 2rem;
  font-weight: 400; */
  line-height: 1.3;
  /* color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap; */
  flex-shrink: 0;
}
.card-details .auto-group-vezg-ES8 {
  /* margin-bottom: 2.7rem; */
  /* box-sizing: border-box; */
  padding: 1.2rem 3.2rem;
  max-width: 22.7rem;
  /* font-size: 1.8rem;
  font-weight: 400; */
  /* line-height: 1.4444444444; */
  /* color: #717171;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap; */
  border: solid 0.2rem #d2d2d2;
  border-radius: 0.6rem;
  /* flex-shrink: 0; */
}
.card-details .save-this-card-6jE {
  margin-left: 8.9rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  color: #111535;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  flex-shrink: 0;
}
.card-details .group-1261154742-TXr .group-1261154739-p9S {
  margin: 3.6rem 0rem 5.3rem 0rem;
  box-sizing: border-box;
  padding: 1.2rem 3.2rem;
  width: 21.7rem;
  height: calc(100% - 8.9rem);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #717171;
  font-family: Poppins, "Source Sans Pro";
  white-space: nowrap;
  border: solid 0.2rem #d2d2d2;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.card-details .group-1261154740-dcg {
  width: 21.7rem;
  border-radius: 0.6rem;
  flex-shrink: 0;
}
.card-details .group-1261154740-dcg .cid-cvv-b3i {
  margin-bottom: 1rem;
  /* font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  color: #111535;
  font-family: Poppins, "Source Sans Pro"; */
  white-space: nowrap;
  line-height: 1.3;
}
.card-details .group-1261154740-dcg .auto-group-wzly-vbn {
  box-sizing: border-box;
  padding: 1.2rem 2.2rem;
  /* width: 100%;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4444444444;
  color: #717171;
  font-family: Poppins, "Source Sans Pro"; */
  white-space: nowrap;
  border: solid 0.2rem #d2d2d2;
  border-radius: 0.6rem;
}

.close-iGk {
  width: 100%;
  height: 5.6rem;
  /* position: absolute;
  left: 35.2rem;
  top: 80.7rem; */
  /* font-size: 3.2rem;
  font-weight: 500;
  line-height: 0.8125;
  color: #ffffff;
  background-color: #af2245;
  font-family: Poppins, "Source Sans Pro"; */
  /* white-space: nowrap; */
  margin-top: 10%;
}

.pay-input-size {
  max-width: 95px;
}

@media screen and (max-width: 1326px) {
  .cid-cvv{
    margin-top: 1rem;
  }
}
@media screen and (max-width: 820px) {
  #Paymentdetails .modal-dialogg {
    max-width: 96% !important;
    margin-top: 15px;
}
}

@media screen and (max-width: 1024px) {
  .auto-group-fdty-WUL {
    flex-direction: column;
  }
  .auto-group-fdty-WUL .auto-group-xk7n-qFi{
    width: 100%;
  }

  .auto-group-fdty-WUL .auto-group-xk7n-qFi .group-1261154784-xbE {
    max-width: 80rem;
  }
  .auto-group-fdty-WUL .auto-group-xk7n-qFi .group-1261154776-kDa {
    max-width: 80rem;
    padding: 1.6rem 0rem 4rem 0rem;
  }
  .auto-group-fdty-WUL .group-1261154781-8z8 {
    width: 80rem;
  }

}

@media screen and (max-width: 820px) {
  .auto-group-fdty-WUL .group-1261154781-8z8 {
    width: 60rem;
  }
  
}

@media screen and (max-width: 768px) {
  .auto-group-fdty-WUL .group-1261154781-8z8 {
    width: 55rem;
  }
}
