.group-1261154697-tnQ {
  margin-bottom: 4.6rem;
  box-sizing: border-box;
  padding: 2.4rem 4.4rem 3.4rem 10.4rem;
  /* padding: 2rem; */
  /* width: 100%; */
  height: 8.5rem;
  background-color: rgba(175, 34, 69, 0.1000000015);
  flex-shrink: 0;
}

.group-1261154697-tnQ .group-1261154093-zqS {
  /* width: 85.3rem;
  height: 100%; */
  display: flex;
  /* align-items: center; */
}

.group-1261154697-tnQ .group-1261154093-zqS .group-1261154076-Kcp {
  margin-right: 4rem;
  /* height: 100%; */
  display: flex;
  align-items: center;
  cursor: pointer;
  /* flex-shrink: 0; */
}

.group-1261154697-tnQ
  .group-1261154093-zqS
  .group-1261154076-Kcp
  .group-1261154072-sPS {
  margin: -0.8rem 1.64rem 0.1rem 0rem;
}

.auto-group-fdty-WUL {
  margin: 0rem 9rem 4rem 9rem;
  /* height: 70rem; */
  display: flex;
}

.auto-group-fdty-WUL .auto-group-xk7n-qFi {
  margin-right: 3.8rem;
  width: 60%;
}

.auto-group-fdty-WUL .auto-group-xk7n-qFi .group-1261154784-xbE {
  margin-bottom: 4.8rem;
  box-sizing: border-box;
  padding: 1.5rem 1.6rem 4.3rem 1.6rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  background-color: #ffffff;
  border-radius: 0.6rem;
}

.auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S {
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: column;
}

.auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .group-1261154718-RUp {
  margin: 0rem 20.905rem 1.6rem 0rem;
  height: 7.9rem;
  display: flex;
}

.auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .group-1261154718-RUp
  .logo-9Qp {
  margin: 0.3rem 1.895rem 0rem 0rem;
  height: 9rem;
  width: 9rem;
}

.auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .auto-group-qwxx-Qzx {
  margin-bottom: 1.6rem;
  width: 100%;
  height: 3.6rem;
  display: flex;
  align-items: center;
}

.auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .auto-group-qwxx-Qzx
  .group-1261154723-uB2 {
  display: flex;
}

.auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154725-g1S
  .auto-group-qwxx-Qzx
  .group-1261154723-uB2
  .vector-qqN {
  margin-right: 1.6rem;
  width: 2.4rem;
  height: 2.4rem;
}

.auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154784-xbE
  .group-1261154729-CJY {
  display: flex;
  align-items: flex-start;
}

.group-1261154727-8xt {
  margin-right: 1.6384rem;
  width: 1.6616rem;
}

.auto-group-fdty-WUL .auto-group-xk7n-qFi .group-1261154776-kDa {
  box-sizing: border-box;
  padding: 1.6rem 1.6rem 4rem 1.6rem;
  row-gap: 1.6rem;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154766-Ttg {
  box-sizing: border-box;
  padding: 1.8rem 1.6rem 1.7rem 1.6rem;
  display: flex;
  justify-content: space-between;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
}

.auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154766-Ttg
  .group-1261154735-nvx {
  /* margin: 0.2rem 20.219rem 0rem 0rem; */
  display: flex;
}

.auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154766-Ttg
  .group-1261154735-nvx
  .group-1261154767-vXN {
  margin: 0rem 1.6rem 0.1rem 0rem;
  width: 2.7815rem;
}

.auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154767-gur {
  height: 6.4rem;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
}

.auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154767-gur
  .group-1261154775-3kQ {
  box-sizing: border-box;
  padding: 1.6rem 1.6rem 1.6rem 1.7rem;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  justify-content: space-between;
}

.auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .auto-group-an2g-hyN {
  height: 6.4rem;
  position: relative;
}

.auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .auto-group-an2g-hyN
  .group-1261154771-miL {
  box-sizing: border-box;
  padding: 1.8rem 1.65rem 1.9rem 1.7rem;
  height: 6.4rem;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  justify-content: space-between;
}

.auto-group-fdty-WUL
  .auto-group-xk7n-qFi
  .group-1261154776-kDa
  .group-1261154772-Jbn {
  box-sizing: border-box;
  padding: 1.8rem 1.65rem 1.9rem 1.7rem;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  justify-content: space-between;
}

.auto-group-fdty-WUL .auto-group-xk7n-qFi .group-1261154776-kDa .vector-Znc {
  /* margin: 4px 0px 0 450px; */
  height: 1.8rem;
}

.auto-group-fdty-WUL .group-1261154781-8z8 {
  width: 62rem;
}

.auto-group-fdty-WUL .group-1261154781-8z8 .auto-group-anua-c8c {
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  background-color: #ffffff;
  border-radius: 0.6rem;
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-zfxx-v9J {
  margin-bottom: 1.6rem;
  box-sizing: border-box;
  padding: 1.6rem 1.4rem 1.6rem 1.8rem;
  height: 6.4rem;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  background-color: #ffffff;
  border-radius: 0.6rem 0.6rem 0 0;
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-zfxx-v9J
  .group-1261154745-d3i {
  /* height: 100%; */
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-zfxx-v9J
  .group-1261154745-d3i
  .group-1261154734-ApL {
  /* margin-right: 19.1rem;
  height: 100%; */
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-zfxx-v9J
  .group-1261154745-d3i
  .image-79-n52 {
  width: 7.3998rem;
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154756-GF6 {
  margin-bottom: 1.6rem;
  box-sizing: border-box;
  padding: 1.6rem;
  height: 6.4rem;
  background-color: #ffffff;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154756-GF6
  .group-1261154746-boA {
  height: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

/* .auto-group-fdty-WUL .group-1261154781-8z8 .auto-group-anua-c8c .group-1261154756-GF6 .group-1261154746-boA .group-1261154735-xdi {
  margin-right: 20.7rem;
  height: 100%;
} */

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154756-GF6
  .group-1261154746-boA
  .image-80-NxL {
  width: 7.39rem;
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154757-SxC {
  margin-bottom: 1.6rem;
  box-sizing: border-box;
  padding: 1.6rem 1.633rem 1.6rem 1.6rem;
  height: 6.4rem;
  background-color: #ffffff;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154757-SxC
  .group-1261154750-ANQ {
  /* height: 100%; */
  display: flex;
  justify-content: space-between;
}

/* .auto-group-fdty-WUL .group-1261154781-8z8 .auto-group-anua-c8c .group-1261154757-SxC .group-1261154750-ANQ .group-1261154735-iep {
  margin-right: 22.1rem;
  height: 100%;
  display: flex;
} */

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154757-SxC
  .group-1261154750-ANQ
  .image-83-Z9e {
  width: 7.3967rem;
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8 {
  margin-bottom: 3.2rem;
  box-sizing: border-box;
  padding: 1.6rem 1.633rem 1.6rem 1.6rem;
  /* width: 100%;
  height: 6.4rem; */
  background-color: #ffffff;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk {
  /* height: 100%; */
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

/* .auto-group-fdty-WUL .group-1261154781-8z8 .auto-group-anua-c8c .group-1261154758-2J8 .group-1261154751-9Nk .group-1261154735-VhW {
  height: 100%;
} */

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk
  .auto-group-46ep-Y9z {
  box-sizing: border-box;
  /* padding: 0.4rem 0rem 0.4rem 10.6rem; */
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk
  .auto-group-46ep-Y9z
  .image-81-fVW {
  margin-right: 1.6rem;
  width: 7.186rem;
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk
  .auto-group-46ep-Y9z
  .image-84-zng {
  margin-right: 1.6rem;
  width: 7.4113rem;
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk
  .auto-group-46ep-Y9z
  .image-82-YZJ {
  margin-right: 1.6001rem;
  width: 2.4048rem;
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk
  .auto-group-46ep-Y9z
  .image-85-6Kv {
  margin-right: 1.6rem;
  width: 3.065rem;
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .group-1261154758-2J8
  .group-1261154751-9Nk
  .auto-group-46ep-Y9z
  .image-86-Cdr {
  width: 2.4rem;
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-fkt2-aeQ {
  box-sizing: border-box;
  padding: 1.6rem 1.6rem 2.4rem 1.6rem;
  flex-direction: column;
}

.auto-group-fdty-WUL
  .group-1261154781-8z8
  .auto-group-anua-c8c
  .auto-group-fkt2-aeQ
  .group-1261154765-sYx {
  box-sizing: border-box;
  padding: 0.9rem 0.9rem 0rem 1.6rem;
  width: 100%;
  border-radius: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
}

.group-1261154783-zX6 {
  box-sizing: border-box;
  /* padding: 0.8rem 10rem 0.9rem 10rem;
  height: 4.4rem;
  display: flex;
  justify-content: space-between; */
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
  margin-bottom: -5px;
}

.group-1261154783-zX6 .group-1261154782-ue4 {
  /* margin-right: 69.4rem; */
  display: flex;
}

.group-1261154783-zX6 .group-1261154782-ue4 .group-1261154778-Tfa {
  margin: 0.2rem 1.2rem 0.1rem 0rem;
  width: 2.4rem;
  position: relative;
}

.group-1261154783-zX6 .group-1261154782-ue4 .group-1261154778-Tfa .c-ydv {
  display: flex;
  justify-content: center;
  font-size: 15px;
}

.radio_pos {
  position: absolute;
}

.padd-left {
  padding-left: 40px;
  /* top: 5px; */
}

.group-1261154783-zX6
  .group-1261154782-ue4
  .group-1261154778-Tfa
  .ellipse-89-tVz {
  width: 2.4rem;
  height: 2.4rem;
  border: solid 0.1rem #ffffff;
  box-sizing: border-box;
  border-radius: 1.2rem;
}

.group-1261154783-zX6 .group-1261154780-kYC {
  display: flex;
  gap: 35px;
}

/* .group-1261154765-sYx .m-l{
  margin-left: 310px;
} */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f7e9ec;
}

input:focus + .slider {
  box-shadow: 0 0 1px blue;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #af2245;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.radio_btn_container {
  position: relative;
  cursor: pointer;
  /* margin: 1rem -12.8rem 1.6rem 0rem; */
  /* width: calc(100% - 27.2rem); */
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.text-end {
  text-align: end;
}

/* ================= */
.first-card .first-pay-card {
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015) !important;
  background-color: #ffffff !important;
  border-radius: 0.6rem !important;
  border: none !important;
}

/* .container {
  max-width: 1200px;
} */

.card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 50px;
  /* Adjust as needed */
}

.icon {
  width: 20px;
  /* Adjust as needed */
  margin-right: 5px;
}

.payment-icon {
  width: 30px;
  /* Adjust as needed */
  margin-left: 10px;
}

.payment-icons img {
  width: 30px;
  /* Adjust as needed */
  margin-right: 5px;
}

.btn {
  background-color: #6c63ff;
  /* Primary color */
  color: white;
}

/* ---  */
.first-card .logo-9Qp {
  /* margin: 0.3rem 1.895rem 0rem 0rem; */
  height: 9rem;
  width: 9rem;
}

.sec-card .sec-pay-card {
  border: none;
  background: transparent;
  box-shadow: none;
  margin-top: 20px;
  padding: 17px;
}

.sec-card .sec-evr-card {
  border: none;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sec-card .input-group {
  margin-bottom: 1rem;
}

.sec-card .vector-Znc {
  width: 1rem;
}

.image-79-n52 {
  width: 7.3998rem;
}

.datee {
  align-items: end;
}

/* .sec-card .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.sec-card .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sec-card .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: switch;
  transition: .4s;
  border-radius: 20px;
}

.sec-card .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.sec-card input:checked + .slider {
  background-color: #6c63ff; 
}

.sec-card input:checked + .slider:before {
  transform: translateX(14px);
} */

.sec-card .payment-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.third-card .card {
  border: none;
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  background-color: #ffffff;
  border-radius: 0.6rem;
}

.third-card .sec-evr-card {
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;

  margin-bottom: 1.6rem;
  box-sizing: border-box;
  /* padding: 1.6rem 1.4rem 1.6rem 1.8rem; */
  box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015);
  background-color: #ffffff;
  border-radius: 0.6rem 0.6rem 0 0;
}

.amount-pay-btn {
  box-sizing: border-box;
  padding: 0.9rem 0.9rem 0rem 1.6rem;
  width: 100%;
  border-radius: 0.6rem;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(100.93deg, #af2245 0%, #602d8a 100%);
}

.price-pack-sec {
  /* box-shadow: 0.5rem 0.8rem 3.2rem rgba(0, 0, 0, 0.1000000015); */

  border-radius: 0.6rem;
  margin-top: 10px;
}

.image-81-fVW {
  margin-right: 1.6rem;
  width: 7.186rem;
}

.image-84-zng {
  margin-right: 1.6rem;
  width: 7.4113rem;
}

.image-82-YZJ {
  margin-right: 1.6001rem;
  width: 2.4048rem;
}

.image-85-6Kv {
  margin-right: 1.6rem;
  width: 3.065rem;
}

.image-86-Cdr {
  width: 2.4rem;
}
.payment-footer {
  font-size: 18px;
}
@media (max-width: 768px) {
  .datee {
    align-items: start;
    margin-bottom: 15px;
  }
}

@media (max-width: 600px) {
  .group-1261154697-tnQ {
    padding: 2rem;
    margin-bottom: 0.6rem;
  }

  .datee {
    align-items: start;
    margin-bottom: 15px;
  }

  .sec-card .sec-pay-card {
    padding: 17px 4px;
  }

  .sec-card .sec-evr-card {
    margin-top: 7px;
    margin-bottom: 9px;
  }

  .image-81-fVW {
    margin-right: 1.6rem;
    width: 4.186rem;
  }

  .image-84-zng {
    margin-right: 1.6rem;
    width: 4.4113rem;
  }
  .third-card .radio-button-span::after {
    content: "";
    position: absolute;
    /* top: 4px; */
    left: 0.5px;
    width: 25px;
    height: 25px;
  }
  .third-card .radio-button-span::before {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #af2245;
    display: none;
  }

  .payment-footer {
    font-size: 10px;
  }
  .group-1261154783-zX6 .group-1261154782-ue4 .group-1261154778-Tfa {
    margin: 0rem 1.2rem 1.1rem 0rem;
  }
  .group-1261154783-zX6
    .group-1261154782-ue4
    .group-1261154778-Tfa
    .ellipse-89-tVz {
    width: 2.1rem;
    height: 2.1rem;
  }
  .group-1261154783-zX6 .group-1261154782-ue4 .group-1261154778-Tfa .c-ydv {
    font-size: 13px;
  }
}
